/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CreateVoucherPayload, VoucherDeliveryMethod } from "src/app/types/api/voucher.types";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateEmail, validateField, validateFutureDate, validateNumberField, validatePhone } from "src/app/utils/forms";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import useForm from "src/app/utils/hooks/useForm";
import Input from "src/app/components/Form/Input.component";
import PhoneInput from "src/app/components/Form/PhoneInput.component";
import CheckboxComponent from "src/app/components/Form/Checkbox.component";
import { Button, Checkbox, Label, Modal, Radio, Tabs, TabsRef, Tooltip } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import { LocaleFromISO } from "src/app/utils/luxon";
import { DateTime } from "luxon";
import DatePicker from "src/app/components/Form/DatePicker.component";
import moment from "moment";
import { TbInfoCircleFilled } from "react-icons/tb";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		onCreate: (values: CreateVoucherPayload) => void
		isOpen: boolean
		handleClose: () => void
	}

type CreateVoucherForm = {
	nameReceiver: string
	surnameReceiver: string

	nameBuyer: string
	surnameBuyer: string
	emailBuyer: string
	phoneBuyer: string

	price: string
	expiresAt: Date
	isActive: boolean

	// Delivery method fields
	deliveryMethod: VoucherDeliveryMethod
	city: string
	street: string
	postalCode: string

	// Company fields
	isForCompany: boolean
	companyName: string
	companyNip: string
	companyAddress: string
	companyPostCode: string
	companyCity: string
}

const validator: FormValidator<CreateVoucherForm> = {
	nameReceiver: (nameReceiver, optional) => validateField("Imię osoby obdarowywanej", nameReceiver, optional, { maxLength: 50 }),
	surnameReceiver: (surnameReceiver, optional) => validateField("Nazwisko osoby obdarowywanej", surnameReceiver, optional, { maxLength: 100 }),

	nameBuyer: (nameBuyer, optional) => validateField("Imię", nameBuyer, optional, { maxLength: 50 }),
	surnameBuyer: (surnameBuyer, optional) => validateField("Nazwisko", surnameBuyer, optional, { maxLength: 100 }),
	emailBuyer: (emailBuyer, optional) => validateEmail(emailBuyer, optional),
	phoneBuyer: (phoneBuyer, optional) => validatePhone(phoneBuyer, optional),

	price: (price, optional) => validateNumberField("Kwota", price, optional, 50, 10000),
	expiresAt: (expiresAt, optional) => validateFutureDate("Data wygaśnięcia", expiresAt, optional),
	isActive: () => null,

	deliveryMethod: (deliveryMethod, optional) => validateField("Opcja dostawy", deliveryMethod, optional),
	street: (street, optional, form) => {
		if (form.deliveryMethod.value === VoucherDeliveryMethod.COURIER) {
			return validateField("Adres", street, optional, { maxLength: 150 });
		} else {
			return null;
		}
	},
	city: (city, optional, form) => {
		if (form.deliveryMethod.value === VoucherDeliveryMethod.COURIER) {
			return validateField("Miasto", city, optional, { maxLength: 50 });
		} else {
			return null;
		}
	},
	postalCode: (postalCode, optional, form) => {
		if (form.deliveryMethod.value === VoucherDeliveryMethod.COURIER) {
			return validateField("Kod pocztowy", postalCode, optional, { maxLength: 10 });
		} else {
			return null;
		}
	},

	//Company fields
	isForCompany: () => null,
	companyName: (companyName, optional) => validateField("Nazwa firmy", companyName, optional, { maxLength: 100 }),
	companyNip: (companyNip, optional) => validateField("NIP", companyNip, optional, { maxLength: 20 }),
	companyAddress: (companyAddress, optional) => validateField("Adres", companyAddress, optional, { maxLength: 150 }),
	companyPostCode: (companyPostCode, optional) => validateField("Kod pocztowy", companyPostCode, optional, { maxLength: 10 }),
	companyCity: (companyCity, optional) => validateField("Miasto", companyCity, optional, { maxLength: 50 }),
};

const initialState: Form<CreateVoucherForm> = {
	nameReceiver: createFormField("", { optional: true }),
	surnameReceiver: createFormField("", { optional: true }),

	nameBuyer: createFormField(""),
	surnameBuyer: createFormField(""),
	emailBuyer: createFormField(""),
	phoneBuyer: createFormField(""),

	price: createFormField(""),
	expiresAt: createFormField(moment().add(6, "months").toDate()),
	isActive: createFormField(true),

	deliveryMethod: createFormField(VoucherDeliveryMethod.ONLINE),
	city: createFormField("", { disabled: true }),
	street: createFormField("", { disabled: true }),
	postalCode: createFormField("", { disabled: true }),

	//Company fields
	isForCompany: createFormField(false),
	companyName: createFormField("", { disabled: true }),
	companyNip: createFormField("", { disabled: true }),
	companyAddress: createFormField("", { disabled: true }),
	companyPostCode: createFormField("", { disabled: true }),
	companyCity: createFormField("", { disabled: true }),
};

function CreateVoucherModal(props: Props) {

	const {
		isOpen,
		handleClose,
		onCreate,
		isCreating,
	} = props;

	const [ activeTab, setActiveTab ] = useState(0);

	const tabsRef = useRef<TabsRef>(null);

	const handleCloseWithTabSet = () => {
		setActiveTab(0);
		resetForm();
		handleClose();
	};

	const _handleSubmit = (values: CreateVoucherForm) => {
		const createVoucherCore = {
			nameReceiver: values.nameReceiver,
			surnameReceiver: values.surnameReceiver,
			nameBuyer: values.nameBuyer,
			surnameBuyer: values.surnameBuyer,
			emailBuyer: values.emailBuyer,
			phoneBuyer: values.phoneBuyer,
			price: +values.price,
			expiresAt: values.expiresAt.toISOString(),
			isActive: values.isActive,
		};

		if (values.isForCompany) {
			// For companies
			const companyDetails = {
				companyCity: values.companyCity,
				companyName: values.companyName,
				companyNip: values.companyNip,
				companyAddress: values.companyAddress,
				companyPostCode: values.companyPostCode,
			};

			if (values.deliveryMethod === VoucherDeliveryMethod.COURIER) {
				// Company + Courier
				onCreate({
					...createVoucherCore,
					...companyDetails,
					isForCompany: true,
					deliveryMethod: VoucherDeliveryMethod.COURIER,
					city: values.city,
					street: values.street,
					postalCode: values.postalCode,
				});
			} else {
				// Company + Online
				onCreate({
					...createVoucherCore,
					...companyDetails,
					isForCompany: true,
					deliveryMethod: VoucherDeliveryMethod.ONLINE,
				});
			}
		} else {
			// No company
			if (values.deliveryMethod === VoucherDeliveryMethod.COURIER) {
				// No company + Courier
				onCreate({
					...createVoucherCore,
					isForCompany: false,
					deliveryMethod: VoucherDeliveryMethod.COURIER,
					city: values.city,
					street: values.street,
					postalCode: values.postalCode,
				});
			} else {
				// No company + Online
				onCreate({
					...createVoucherCore,
					isForCompany: false,
					deliveryMethod: VoucherDeliveryMethod.ONLINE,
				});
			}
		}
		handleClose();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		resetForm,
		toggleDisable,
	} = useForm(initialState, validator, _handleSubmit);

	const handleFvCheckboxChange = () => {
		const newValue = !form.isForCompany.value;
		const getError = (fieldName: "companyName" | "companyNip" | "companyAddress" | "companyPostCode" | "companyCity") => {
			if (newValue) return form[ fieldName ].error;
			return null;
		};
		setForm({
			...form,
			isForCompany: { ...form.isForCompany, value: newValue },
			companyName: {
				...form.companyName,
				disabled: !newValue,
				error: getError("companyName"),
			},
			companyNip: {
				...form.companyNip,
				disabled: !newValue,
				error: getError("companyNip"),
			},
			companyAddress: {
				...form.companyAddress,
				disabled: !newValue,
				error: getError("companyAddress"),
			},
			companyPostCode: {
				...form.companyPostCode,
				disabled: !newValue,
				error: getError("companyPostCode"),
			},
			companyCity: {
				...form.companyCity,
				disabled: !newValue,
				error: getError("companyCity"),
			},
		});
	};

	useEffect(() => {
		if (!isOpen && !isCreating) {
			resetForm();
			setActiveTab(0);
		}
	}, [ isOpen, isCreating ]);

	const disableSecondTab = isNull(form.price.value) ||
		isNull(form.expiresAt.value) ||
		(
			form.deliveryMethod.value === VoucherDeliveryMethod.COURIER &&
			(isEmptyString(form.street.value) || isEmptyString(form.city.value) || isEmptyString(form.postalCode.value) || isNotNull(form.street.error) || isNotNull(form.postalCode.error)  || isNotNull(form.city.error))
		) ||
		isEmptyString(form.price.value) ||
		isNotNull(form.price.error);

	return (
		<Modal
			show={ isOpen || isCreating }
			onClose={ handleCloseWithTabSet }
			size="6xl"
			root={ document.body }
			className="[&>div]:!max-h-[100svh]"
			key={ (isOpen || isCreating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Dodaj nowy voucher
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body>
					<Tabs
						ref={ tabsRef }
						aria-label="Default tabs"
						className="w-full"
						style="default"
						onActiveTabChange={ tab => setActiveTab(tab) }
					>
						<Tabs.Item active title="Voucher">
							<div className="space-y-3">
								<Input
									formItem={ form.price }
									name="price"
									className="w-full"
									inputProps={ {
										type: "number",
										onChange: ({ target: { value } }) => handleChange("price", value),
										onBlur: () => handleBlur("price"),
										placeholder: "100",
									} }
									label="Kwota vouchera (zł)"
								/>
								<DatePicker
									label={
										<div className="flex flex-row items-center gap-1">
											<span>Data wygaśniecia</span>
											<Tooltip content="Domyślnie ustawiane 6 miesiący">
												<TbInfoCircleFilled className="text-myPrimary-gray-500 h-5 w-5"/>
											</Tooltip>
										</div>
									}
									className="flex-grow"
									formItem={ form.expiresAt }
									datepickerProps={ {
										minDate: LocaleFromISO(DateTime.now().toISO())?.toJSDate(),
									} }
									onChange={ date => handleChange("expiresAt", date) }
									onBlur={ () => handleBlur("expiresAt") }
								/>
								<div
									className="flex items-center gap-x-3 w-fit"
									onClick={ () => handleChange("isActive", !form.isActive.value) }
								>
									<Checkbox
										name="isActive"
										checked={ form.isActive.value }
										onChange={ () => undefined }
									/>
									<Label htmlFor="isActive">
										Aktywny
									</Label>
								</div>
								<div className="flex flex-col gap-2">
									<h3 className="text-base font-medium text-dark-textGray">Metoda dostawy</h3>
									<div className="flex items-center gap-2">
										<Radio
											id="deliveryMethodOnline"
											name="deliveryMethodOnline"
											onChange={ () => {
												handleChange("deliveryMethod", VoucherDeliveryMethod.ONLINE);
												handleBlur("deliveryMethod");
											} }
											checked={ form.deliveryMethod.value === VoucherDeliveryMethod.ONLINE }
										/>
										<Label htmlFor="deliveryMethodOnline">
											Online
										</Label>
									</div>
									<div className="flex items-center gap-2">
										<Radio
											id="deliveryMethodCourier"
											name="deliveryMethodCourier"
											onChange={ () => {
												handleChange("deliveryMethod", VoucherDeliveryMethod.COURIER);
												handleBlur("deliveryMethod");
												toggleDisable("city", false);
												toggleDisable("street", false);
												toggleDisable("postalCode", false);
											} }
											checked={ form.deliveryMethod.value === VoucherDeliveryMethod.COURIER }
										/>
										<Label htmlFor="deliveryMethodCourier">
											Kurier (+20 zł)
										</Label>
									</div>
								</div>
								{
									form.deliveryMethod.value === VoucherDeliveryMethod.COURIER &&
                                    <div>
                                        <h3 className="text-base font-medium text-dark-textGray mb-2">
                                            Adres dostawy
                                        </h3>
                                        <Input
                                            formItem={ form.street }
                                            name="street"
                                            className="w-full"
                                            inputProps={ {
												type: "text",
												onChange: ({ target: { value } }) => handleChange("street", value),
												onBlur: () => handleBlur("street"),
											} }
                                            label="Adres (ulica, numer domu/mieszkania)"
                                        />
                                        <div className="flex gap-2 sm:gap-4 w-full flex-col sm:flex-row">
                                            <Input
                                                formItem={ form.postalCode }
                                                name="postalCode"
                                                className="w-full"
                                                inputProps={ {
													type: "text",
													onChange: ({ target: { value } }) => handleChange("postalCode", value),
													onBlur: () => handleBlur("postalCode"),
												} }
                                                label="Kod pocztowy"
                                            />
                                            <Input
                                                formItem={ form.city }
                                                name="city"
                                                className="w-full"
                                                inputProps={ {
													type: "text",
													onChange: ({ target: { value } }) => handleChange("city", value),
													onBlur: () => handleBlur("city"),
												} }
                                                label="Miasto"
                                            />
                                        </div>
                                    </div>
								}
							</div>
						</Tabs.Item>
						<Tabs.Item title="Dane kupującego" disabled={ disableSecondTab }>
							<div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-6 min-h-[450px]">
								<div className="flex flex-col gap-6">
									<div className="flex flex-col gap-4">
										<h2 className="text-mySecondary-purple-500 font-bold text-lg">
											Dane osoby obdarowywanej (opcjonalne)
										</h2>
										<div className="w-full flex flex-col justify-stretch gap-2">
											<div className="flex gap-2 w-full flex-col sm:flex-row">
												<Input
													formItem={ form.nameReceiver }
													name="receiver-name"
													className="w-full"
													inputProps={ {
														type: "text",
														onChange: ({ target: { value } }) => handleChange("nameReceiver", value),
														onBlur: () => handleBlur("nameReceiver"),
													} }
													label="Imię"
												/>
												<Input
													formItem={ form.surnameReceiver }
													name="receiver-surname"
													className="w-full"
													inputProps={ {
														type: "text",
														onChange: ({ target: { value } }) => handleChange("surnameReceiver", value),
														onBlur: () => handleBlur("surnameReceiver"),
													} }
													label="Nazwisko"
												/>
											</div>
										</div>
									</div>
								</div>
								<div>
									<h2 className="text-mySecondary-purple-500 mb-4 font-bold text-lg">
										Dane kupującego
									</h2>
									<div className="w-full flex flex-col justify-stretch gap-2 mb-4">
										<div className="flex gap-2 w-full flex-col sm:flex-row">
											<Input
												formItem={ form.nameBuyer }
												label="Imię"
												name="buyer-name"
												className="w-full"
												inputProps={ {
													type: "text",
													onChange: ({ target: { value } }) => handleChange("nameBuyer", value),
													onBlur: () => handleBlur("nameBuyer"),
												} }
											/>
											<Input
												formItem={ form.surnameBuyer }
												name="buyer-surname"
												label="Nazwisko"
												className="w-full"
												inputProps={ {
													type: "text",
													onChange: ({ target: { value } }) => handleChange("surnameBuyer", value),
													onBlur: () => handleBlur("surnameBuyer"),
												} }
											/>
										</div>
										<Input
											formItem={ form.emailBuyer }
											name="buyer-email"
											className="mb-2"
											inputProps={ {
												type: "email",
												onChange: ({ target: { value } }) => handleChange("emailBuyer", value),
												onBlur: () => handleBlur("emailBuyer"),
											} }
											label="E-mail"
										/>
										<div className="w-full flex items-start gap-2">
											<PhoneInput
												name="buyer-phone"
												label="Nr telefonu"
												className="w-full"
												phoneInputProps={ {
													country: "pl",
												} }
												formItem={ form.phoneBuyer }
												onChange={ phone => {
													handleChange("phoneBuyer", phone);
												} }
												onBlur={ () => handleBlur("phoneBuyer") }
											/>
										</div>
									</div>
									<div className="mb-5 sm:mb-10">
										<CheckboxComponent
											formItem={ form.isForCompany }
											onChange={ handleFvCheckboxChange }
											className="mb-4 w-fit"
											name="payment-isForCompany"
											label="Dane firmy"
										/>
										{
											form.isForCompany.value &&
                                            <div className="flex flex-col gap-2">
                                                <Input
                                                    formItem={ form.companyName }
                                                    name="payment-company-name"
                                                    inputProps={ {
														type: "text",
														onChange: ({ target: { value } }) => handleChange("companyName", value),
														onBlur: () => handleBlur("companyName"),
													} }
                                                    label="Nazwa firmy"
                                                />
                                                <Input
                                                    formItem={ form.companyNip }
                                                    name="payment-company-nip"
                                                    inputProps={ {
														type: "text",
														onChange: ({ target: { value } }) => handleChange("companyNip", value),
														onBlur: () => handleBlur("companyNip"),
													} }
                                                    label="NIP"
                                                />
                                                <div className="flex flex-col gap-2">
                                                    <Input
                                                        formItem={ form.companyAddress }
                                                        name="payment-company-address"
                                                        inputProps={ {
															type: "text",
															onChange: ({ target: { value } }) => handleChange("companyAddress", value),
															onBlur: () => handleBlur("companyAddress"),
														} }
                                                        label="Adres firmy"
                                                    />
                                                    <div className="flex flex-col sm:flex-row gap-2">
                                                        <Input
                                                            formItem={ form.companyPostCode }
                                                            name="payment-company-post-code"
                                                            className="sm:w-1/2"
                                                            inputProps={ {
																type: "text",
																onChange: ({ target: { value } }) => handleChange("companyPostCode", value),
																onBlur: () => handleBlur("companyPostCode"),
															} }
                                                            label="Kod pocztowy"
                                                        />
                                                        <Input
                                                            formItem={ form.companyCity }
                                                            name="payment-company-city"
                                                            className="sm:w-1/2"
                                                            inputProps={ {
																type: "text",
																onChange: ({ target: { value } }) => handleChange("companyCity", value),
																onBlur: () => handleBlur("companyCity"),
															} }
                                                            label="Miasto"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
										}
									</div>
								</div>
							</div>
						</Tabs.Item>
					</Tabs>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					{
						activeTab === 0 &&
                        <Button
                            type="button"
                            onClick={ () => tabsRef.current?.setActiveTab(1) }
                            disabled={ disableSecondTab }
                        >
                            Dalej
                        </Button>
					}
					{
						activeTab === 1 &&
                        <Button
                            type="submit"
                            isProcessing={ isCreating }
                            disabled={ isCreating }
                        >
                            Stwórz
                        </Button>
					}
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_VOUCHER }),
});

export default connect(mapStateToProps)(CreateVoucherModal);