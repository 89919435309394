/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import classNames from "classnames";
import Input from "src/app/components/Form/Input.component";
import CheckboxComponent from "src/app/components/Form/Checkbox.component";
import PhoneInput from "src/app/components/Form/PhoneInput.component";
import { Collapse } from "react-collapse";
import { Link } from "react-router-dom";
import { Button, Label, Radio } from "flowbite-react";
import { SlArrowRight } from "react-icons/sl";
import React, { useState } from "react";
import { Form, FormItemError, FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateEmail, validateField, validatePhone } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { CreateVoucherPayload, VoucherDeliveryMethod } from "src/app/types/api/voucher.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { priceOptions } from "src/app/types/ui/voucher.types";
import PriceCard from "src/app/components/Voucher/PriceCard.component";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";

export type PurchaseVoucherForm = {
	nameReceiver: string
	surnameReceiver: string

	nameBuyer: string
	surnameBuyer: string
	emailBuyer: string
	phoneBuyer: string
	acceptStatue: boolean

	selectedPrice: string
	price: string

	// Delivery method fields
	deliveryMethod: VoucherDeliveryMethod
	street: string
	city: string
	postalCode: string

	// Company fields
	isForCompany: boolean
	companyName: string
	companyNip: string
	companyAddress: string
	companyPostCode: string
	companyCity: string
}

const purchaseVoucherFormInitialState: Form<PurchaseVoucherForm> = {
	nameReceiver: createFormField("", { optional: true }),
	surnameReceiver: createFormField("", { optional: true }),

	nameBuyer: createFormField(""),
	surnameBuyer: createFormField(""),
	emailBuyer: createFormField(""),
	phoneBuyer: createFormField(""),
	acceptStatue: createFormField(false),

	selectedPrice: createFormField("100"),
	price: createFormField("100"),

	deliveryMethod: createFormField(VoucherDeliveryMethod.ONLINE),
	city: createFormField("", { disabled: true }),
	street: createFormField("", { disabled: true }),
	postalCode: createFormField("", { disabled: true }),

	//Company fields
	isForCompany: createFormField(false),
	companyName: createFormField("", { disabled: true }),
	companyNip: createFormField("", { disabled: true }),
	companyAddress: createFormField("", { disabled: true }),
	companyPostCode: createFormField("", { disabled: true }),
	companyCity: createFormField("", { disabled: true }),
};

const validatePriceField = (value: number | string, optional = false, from = 0, to?: number): FormItemError => {
	const numberValue = +value;
	if ((isNull(value) || isEmptyString(value)) && !optional) {
		return `Uzupełnij kwotę`;
	}
	if (isNaN(numberValue)) {
		return `Kwota jest niepoprawna`;
	}
	if (numberValue < from) {
		return `Kwota musi być większa niż lub równa ${ from }`;
	}
	if (isNotNull(to) && numberValue > to) {
		return `Kwota musi być mniejsza niż lub równa ${ to }`;
	}
	return null;
};

const validator: FormValidator<PurchaseVoucherForm> = {
	nameReceiver: (nameReceiver, optional) => validateField("imię osoby obdarowanej", nameReceiver, optional, { maxLength: 50 }),
	surnameReceiver: (surnameReceiver, optional) => validateField("nazwisko osoby obdarowanej", surnameReceiver, optional, { maxLength: 100 }),

	nameBuyer: (nameBuyer, optional) => validateField("imię kupującego", nameBuyer, optional, { maxLength: 50 }),
	surnameBuyer: (surnameBuyer, optional) => validateField("nazwisko kupującego", surnameBuyer, optional, { maxLength: 100 }),
	emailBuyer: (emailBuyer, optional) => validateEmail(emailBuyer, optional),
	phoneBuyer: (phoneBuyer, optional) => validatePhone(phoneBuyer, optional),
	acceptStatue: (acceptStatue, optional) => {
		if (acceptStatue || optional) return null;
		return "Regulamin jest wymagany";
	},

	selectedPrice: (selectedPrice, optional) => validateField("kwotę", selectedPrice, optional),
	price: (price, optional) => validatePriceField(price, optional, 50, 10000),

	deliveryMethod: (deliveryMethod, optional) => validateField("opcja dostawy", deliveryMethod, optional),
	street: (street, optional, form) => {
		if (form.deliveryMethod.value === VoucherDeliveryMethod.COURIER) {
			return validateField("adres", street, optional, { maxLength: 150 });
		} else {
			return null;
		}
	},
	city: (city, optional, form) => {
		if (form.deliveryMethod.value === VoucherDeliveryMethod.COURIER) {
			return validateField("miasto", city, optional, { maxLength: 50 });
		} else {
			return null;
		}
	},
	postalCode: (postalCode, optional, form) => {
		if (form.deliveryMethod.value === VoucherDeliveryMethod.COURIER) {
			return validateField("kod pocztowy", postalCode, optional, { maxLength: 10 });
		} else {
			return null;
		}
	},

	//Company fields
	isForCompany: () => null,
	companyName: (companyName, optional) => validateField("nazwę firmy", companyName, optional, { maxLength: 100 }),
	companyNip: (companyNip, optional) => validateField("NIP", companyNip, optional, { maxLength: 20 }),
	companyAddress: (companyAddress, optional) => validateField("adres", companyAddress, optional, { maxLength: 150 }),
	companyPostCode: (companyPostCode, optional) => validateField("kod pocztowy", companyPostCode, optional, { maxLength: 10 }),
	companyCity: (companyCity, optional) => validateField("miasto", companyCity, optional, { maxLength: 50 }),
};

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		onPurchaseVoucher: (values: CreateVoucherPayload) => void
	}

function PurchaseVoucherContainer(props: Props) {

	const {
		onPurchaseVoucher,
		isPurchasingVoucher,
	} = props;

	const _handleSubmit = (values: PurchaseVoucherForm) => {
		const purchaseVoucherCore = {
			nameReceiver: values.nameReceiver,
			surnameReceiver: values.surnameReceiver,
			nameBuyer: values.nameBuyer,
			surnameBuyer: values.surnameBuyer,
			emailBuyer: values.emailBuyer,
			phoneBuyer: values.phoneBuyer,
			price: values.selectedPrice === "custom" ? +values.price : +values.selectedPrice,
		};

		if (values.isForCompany) {
			// For companies
			const companyDetails = {
				companyCity: values.companyCity,
				companyName: values.companyName,
				companyNip: values.companyNip,
				companyAddress: values.companyAddress,
				companyPostCode: values.companyPostCode,
			};

			if (values.deliveryMethod === VoucherDeliveryMethod.COURIER) {
				// Company + Courier
				onPurchaseVoucher({
					...purchaseVoucherCore,
					...companyDetails,
					isForCompany: true,
					deliveryMethod: VoucherDeliveryMethod.COURIER,
					city: values.city,
					street: values.street,
					postalCode: values.postalCode,
				});
			} else {
				// Company + Online
				onPurchaseVoucher({
					...purchaseVoucherCore,
					...companyDetails,
					isForCompany: true,
					deliveryMethod: VoucherDeliveryMethod.ONLINE,
				});
			}
		} else {
			// No company
			if (values.deliveryMethod === VoucherDeliveryMethod.COURIER) {
				// No company + Courier
				onPurchaseVoucher({
					...purchaseVoucherCore,
					isForCompany: false,
					deliveryMethod: VoucherDeliveryMethod.COURIER,
					city: values.city,
					street: values.street,
					postalCode: values.postalCode,
				});
			} else {
				// No company + Online
				onPurchaseVoucher({
					...purchaseVoucherCore,
					isForCompany: false,
					deliveryMethod: VoucherDeliveryMethod.ONLINE,
				});
			}
		}
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		toggleDisable,
	} = useForm(purchaseVoucherFormInitialState, validator, _handleSubmit);

	const [ isFvCollapsed, toggleFvCollapse ] = useState(form.isForCompany.value);

	const inputClassNames = "[&>div>input]:placeholder:text-lg [&>div>input]:rounded-xl [&>div>input]:py-3";

	const checkboxLabelClassName = "text-mySecondary-purple-500 font-normal text-sm sm:text-[16px] sm:leading-[17px]";

	const handleFvCheckboxChange = () => {
		const newValue = !form.isForCompany.value;
		const getError = (fieldName: "companyName" | "companyNip" | "companyAddress" | "companyPostCode" | "companyCity") => {
			if (newValue) return form[ fieldName ].error;
			return null;
		};
		setForm({
			...form,
			isForCompany: { ...form.isForCompany, value: newValue },
			companyName: {
				...form.companyName,
				disabled: !newValue,
				error: getError("companyName"),
			},
			companyNip: {
				...form.companyNip,
				disabled: !newValue,
				error: getError("companyNip"),
			},
			companyAddress: {
				...form.companyAddress,
				disabled: !newValue,
				error: getError("companyAddress"),
			},
			companyPostCode: {
				...form.companyPostCode,
				disabled: !newValue,
				error: getError("companyPostCode"),
			},
			companyCity: {
				...form.companyCity,
				disabled: !newValue,
				error: getError("companyCity"),
			},
		});
		toggleFvCollapse(newValue);
	};

	return (
		<div>
			<div className={ classNames(
				"flex gap-10 w-full h-full justify-center py-5",
				"flex-col-reverse",
				"lg:flex-row",
			) }>
				<div className={ classNames(
					"flex flex-col h-max",
					"w-full",
				) }>
					<form onSubmit={ handleSubmit }>
						<div>
							<div className="mb-8">
								<h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">
									Wybierz wartość vouchera
								</h1>
								<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
									{
										priceOptions.map((price) => (
											<PriceCard
												key={ price.value }
												label={ price.label }
												isSelected={ form.selectedPrice.value === price.value }
												onClick={ () => handleChange("selectedPrice", price.value) }
												isCustom={ price.value === "custom" }
												form={ form }
												handleChange={ handleChange }
												handleBlur={ handleBlur }
											/>
										))
									}
								</div>
								{
									(isNotNull(form.selectedPrice.error)) &&
                                    <span className="text-sm text-red-600 dark:text-red-500 font-medium">
                                        Wybierz kwotę vouchera
                                    </span>
								}
							</div>
						</div>
						<div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-8 h-full">
							<div>
								<h2 className="text-mySecondary-purple-500 mb-4 font-bold text-lg">
									Dane kupującego
								</h2>
								<div className="w-full flex flex-col justify-stretch gap-4 mb-4">
									<div className="flex gap-4 w-full flex-col sm:flex-row">
										<Input
											formItem={ form.nameBuyer }
											name="buyer-name"
											className="w-full"
											inputProps={ {
												type: "text",
												onChange: ({ target: { value } }) => handleChange("nameBuyer", value),
												onBlur: () => handleBlur("nameBuyer"),
												placeholder: "imię",
												className: inputClassNames,
											} }
										/>
										<Input
											formItem={ form.surnameBuyer }
											name="buyer-surname"
											className="w-full"
											inputProps={ {
												type: "text",
												onChange: ({ target: { value } }) => handleChange("surnameBuyer", value),
												onBlur: () => handleBlur("surnameBuyer"),
												placeholder: "nazwisko",
												className: inputClassNames,
											} }
										/>
									</div>
									<Input
										formItem={ form.emailBuyer }
										name="buyer-email"
										inputProps={ {
											type: "email",
											onChange: ({ target: { value } }) => handleChange("emailBuyer", value),
											onBlur: () => handleBlur("emailBuyer"),
											placeholder: "email",
											className: inputClassNames,
										} }
										label=""
									/>
									<PhoneInput
										name="buyer-phone"
										className={ classNames("w-full", inputClassNames) }
										phoneInputProps={ {
											country: "pl",
										} }
										formItem={ form.phoneBuyer }
										onChange={ phone => {
											handleChange("phoneBuyer", phone);
										} }
										onBlur={ () => handleBlur("phoneBuyer") }
									/>
								</div>
								<div className="mb-5 sm:mb-10">
									<CheckboxComponent
										formItem={ form.isForCompany }
										onChange={ handleFvCheckboxChange }
										labelClassName={ checkboxLabelClassName }
										className="mb-4 w-fit"
										name="payment-isForCompany"
										label="Chcę otrzymać fakturę VAT"
									/>
									<Collapse isOpened={ isFvCollapsed }>
										<div className="flex flex-col gap-4">
											<Input
												formItem={ form.companyName }
												name="payment-company-name"
												inputProps={ {
													type: "text",
													onChange: ({ target: { value } }) => handleChange("companyName", value),
													onBlur: () => handleBlur("companyName"),
													placeholder: "Nazwa firmy",
													className: inputClassNames,
												} }
												label=""
											/>
											<Input
												formItem={ form.companyNip }
												name="payment-company-nip"
												inputProps={ {
													type: "text",
													onChange: ({ target: { value } }) => handleChange("companyNip", value),
													onBlur: () => handleBlur("companyNip"),
													placeholder: "NIP",
													className: inputClassNames,
												} }
												label=""
											/>
											<Input
												formItem={ form.companyAddress }
												name="payment-company-address"
												inputProps={ {
													type: "text",
													onChange: ({ target: { value } }) => handleChange("companyAddress", value),
													onBlur: () => handleBlur("companyAddress"),
													placeholder: "Adres firmy",
													className: inputClassNames,
												} }
												label=""
											/>
											<div className="flex gap-4 w-full flex-col sm:flex-row">
												<Input
													formItem={ form.companyPostCode }
													name="payment-company-post-code"
													className="sm:w-1/2"
													inputProps={ {
														type: "text",
														onChange: ({ target: { value } }) => handleChange("companyPostCode", value),
														onBlur: () => handleBlur("companyPostCode"),
														placeholder: "Kod pocztowy",
														className: inputClassNames,
													} }
													label=""
												/>
												<Input
													formItem={ form.companyCity }
													name="payment-company-city"
													className="sm:w-1/2"
													inputProps={ {
														type: "text",
														onChange: ({ target: { value } }) => handleChange("companyCity", value),
														onBlur: () => handleBlur("companyCity"),
														placeholder: "Miasto",
														className: inputClassNames,
													} }
													label=""
												/>
											</div>
										</div>
									</Collapse>
								</div>
							</div>
							<div className="flex flex-col gap-6">
								<div className="flex flex-col gap-4">
									<h2 className="text-mySecondary-purple-500 font-bold text-lg">
										Dane osoby obdarowywanej
									</h2>
									<div className="flex gap-4 w-full flex-col sm:flex-row">
										<Input
											formItem={ form.nameReceiver }
											name="receiver-name"
											className="w-full"
											inputProps={ {
												type: "text",
												onChange: ({ target: { value } }) => handleChange("nameReceiver", value),
												onBlur: () => handleBlur("nameReceiver"),
												placeholder: "imię (opcjonalne)",
												className: inputClassNames,
											} }
										/>
										<Input
											formItem={ form.surnameReceiver }
											name="receiver-surname"
											className="w-full"
											inputProps={ {
												type: "text",
												onChange: ({ target: { value } }) => handleChange("surnameReceiver", value),
												onBlur: () => handleBlur("surnameReceiver"),
												placeholder: "nazwisko (opcjonalne)",
												className: inputClassNames,
											} }
										/>
									</div>
								</div>
								<div className="flex flex-col gap-4">
									<h2 className="text-mySecondary-purple-500 font-bold text-lg">
										Metoda dostawy vouchera
									</h2>
									<div className="flex items-center gap-2">
										<Radio
											id="deliveryMethodOnline"
											name="deliveryMethodOnline"
											onChange={ () => {
												handleChange("deliveryMethod", VoucherDeliveryMethod.ONLINE);
												handleBlur("deliveryMethod");
											} }
											checked={ form.deliveryMethod.value === VoucherDeliveryMethod.ONLINE }
										/>
										<Label htmlFor="deliveryMethodOnline">
											Online (GRATIS)
										</Label>
									</div>
									<div className="flex items-center gap-2">
										<Radio
											id="deliveryMethodCourier"
											name="deliveryMethodCourier"
											onChange={ () => {
												handleChange("deliveryMethod", VoucherDeliveryMethod.COURIER);
												handleBlur("deliveryMethod");
												toggleDisable("city", false);
												toggleDisable("street", false);
												toggleDisable("postalCode", false);
											} }
											checked={ form.deliveryMethod.value === VoucherDeliveryMethod.COURIER }
										/>
										<Label htmlFor="deliveryMethodCourier">
											Kurier (+20 zł)
										</Label>
									</div>
									{
										<Collapse isOpened={ form.deliveryMethod.value === VoucherDeliveryMethod.COURIER }>
											<div className="flex gap-4 w-full flex-col mt-2">
												<h3 className="text-mySecondary-purple-500 font-medium text-base">
													Adres dostawy
												</h3>
												<Input
													formItem={ form.street }
													name="address"
													className="w-full"
													inputProps={ {
														type: "text",
														onChange: ({ target: { value } }) => handleChange("street", value),
														onBlur: () => handleBlur("street"),
														placeholder: "adres",
														className: inputClassNames,
													} }
												/>
												<div className="flex gap-4 w-full flex-col sm:flex-row">
													<Input
														formItem={ form.postalCode }
														name="postalCode"
														className="w-full"
														inputProps={ {
															type: "text",
															onChange: ({ target: { value } }) => handleChange("postalCode", value),
															onBlur: () => handleBlur("postalCode"),
															placeholder: "kod pocztowy",
															className: inputClassNames,
														} }
													/>
													<Input
														formItem={ form.city }
														name="city"
														className="w-full"
														inputProps={ {
															type: "text",
															onChange: ({ target: { value } }) => handleChange("city", value),
															onBlur: () => handleBlur("city"),
															placeholder: "miasto",
															className: inputClassNames,
														} }
													/>
												</div>
											</div>
										</Collapse>
									}
								</div>
							</div>
						</div>
						<div className="mt-6">
							<CheckboxComponent
								className="w-fit"
								formItem={ form.acceptStatue }
								onChange={ () => handleChange("acceptStatue", !form.acceptStatue.value) }
								name="payment-acceptStatue"
								labelClassName={ checkboxLabelClassName }
								label={
									<span>
									<span>Akceptuję</span>
									<Link
										to="https://kakadukaraoke.com/regulamin/"
										target="_blank"
										className="text-myPrimary-orange-500 underline mx-1"
										onClick={ e => e.stopPropagation() }
									>
										regulamin
									</Link>
									<span>serwisu</span>
								</span>
								}
							/>
							<div className="text-xs text-gray-400 mb-5">
								Dodatkowo wyrażam zgodę na przetwarzanie moich danych osobowych (imię
								i nazwisko, e-mail, nr telefonu) przez Administratora Danych Osobowych (ADO).
							</div>
							<Button
								color="orange-full"
								type="submit"
								className="max-w-max [&>span]:text-xl [&>span]:!py-[4px] [&>span]:!px-4 mb-5 rounded-xl"
								disabled={ isPurchasingVoucher || !form.acceptStatue.value }
							>
								{
									isPurchasingVoucher
										?
										<div className="mx-auto">
											<BeatLoader size={ 10 } color="white"/>
										</div>
										:
										<div className="flex items-center">
											<span>płacę</span>
											<SlArrowRight className="ml-3 h-3 w-3"/>
										</div>
								}
							</Button>
							<div className="flex items-center gap-3 text-myPrimary-orange-500 text-[10px] underline">
								{/*<a href="#">Polityka zwrotów</a>*/ }
								<a href="https://kakadukaraoke.com/polityka-prywatnosci/" target="_blank">Polityka prywatności</a>
								<a href="https://kakadukaraoke.com/regulamin/" target="_blank">Regulamin</a>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	isPurchasingVoucher: didLoadingRecordExist(state, { loadableType: LoadableType.PURCHASE_VOUCHER }),
});

export default connect(mapStateToProps)(PurchaseVoucherContainer);