import { AbilitiesObject, AllAbilities } from "src/app/types/api/abilities.types";
import { isArray, isNotNull, isNull } from "src/app/utils/typeguards";
import { Nullable } from "src/app/types/util.types";
import { LoggedUser, SelfAbility, UserAbility } from "src/app/types/api/user.types";

import { VenueAbility } from "src/app/types/api/venue.types";
import { OrganizationAbility } from "src/app/types/api/organization.types";
import { RoomAbility } from "src/app/types/api/room.types";
import { ProductAbility } from "src/app/types/api/product.types";
import { DiscountCodeAbility } from "src/app/types/api/discountCode.types";
import { ReservationAbility } from "src/app/types/api/reservation.types";
import { CategoryAbility } from "src/app/types/api/category.types";

import { VoucherAbility } from "src/app/types/api/voucher.types";

// Abilities check import plop - don't remove

function checkSelfAbility(o: LoggedUser, searchedAbility: SelfAbility) {
	if (isNull(o) || !isArray(o.meta?.selfAbilities)) return false;

	return isNotNull(o.meta.selfAbilities.find(ability => ability === searchedAbility));
}

function checkAbility<T extends AllAbilities>(o: Nullable<AbilitiesObject<T>>, searchedAbility: T) {
	if (isNull(o) || !isArray(o.meta?.abilities)) return false;

	return isNotNull(o.meta.abilities.find(ability => ability === searchedAbility));
}

// Self
export const isAppAdministration = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.APP_ADMINISTRATION);
export const canLoggedUserCreateUser = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.CREATE_USER);
export const canLoggedUserListUsers = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.LIST_USERS);
export const canLoggedUserListCategories = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.LIST_USERS);

// User
export const canViewUser = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.VIEW);
export const canUpdateUser = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE);
export const canUpdateUser2Fa = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_2FA);
export const canUpdateUserRole = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_ROLE);
export const canUpdateUserEmail = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_EMAIL);
export const canUpdateUserStatus = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_STATUS);
export const canUpdateUserCompany = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_COMPANY);
export const canUpdateUserPassword = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_PASSWORD);
export const canUpdateUserPermissions = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_PERMISSIONS);
export const canDeleteUser = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.DELETE);
// Venue
export const canViewVenue = (venue: AbilitiesObject<VenueAbility>) => checkAbility(venue, VenueAbility.VIEW);
export const canUpdateVenue = (venue: AbilitiesObject<VenueAbility>) => checkAbility(venue, VenueAbility.UPDATE);
export const canDeleteVenue = (venue: AbilitiesObject<VenueAbility>) => checkAbility(venue, VenueAbility.DELETE);

// Organization
export const canViewOrganization = (organization: AbilitiesObject<OrganizationAbility>) => checkAbility(organization, OrganizationAbility.VIEW);
export const canUpdateOrganization = (organization: AbilitiesObject<OrganizationAbility>) => checkAbility(organization, OrganizationAbility.UPDATE);
export const canDeleteOrganization = (organization: AbilitiesObject<OrganizationAbility>) => checkAbility(organization, OrganizationAbility.DELETE);

// Room
export const canViewRoom = (room: AbilitiesObject<RoomAbility>) => checkAbility(room, RoomAbility.VIEW);
export const canUpdateRoom = (room: AbilitiesObject<RoomAbility>) => checkAbility(room, RoomAbility.UPDATE);
export const canDeleteRoom = (room: AbilitiesObject<RoomAbility>) => checkAbility(room, RoomAbility.DELETE);

// Product
export const canViewProduct = (product: AbilitiesObject<ProductAbility>) => checkAbility(product, ProductAbility.VIEW);
export const canUpdateProduct = (product: AbilitiesObject<ProductAbility>) => checkAbility(product, ProductAbility.UPDATE);
export const canDeleteProduct = (product: AbilitiesObject<ProductAbility>) => checkAbility(product, ProductAbility.DELETE);

// DiscountCode
export const canViewDiscountCode = (discountCode: AbilitiesObject<DiscountCodeAbility>) => checkAbility(discountCode, DiscountCodeAbility.VIEW);
export const canUpdateDiscountCode = (discountCode: AbilitiesObject<DiscountCodeAbility>) => checkAbility(discountCode, DiscountCodeAbility.UPDATE);
export const canDeleteDiscountCode = (discountCode: AbilitiesObject<DiscountCodeAbility>) => checkAbility(discountCode, DiscountCodeAbility.DELETE);

// Reservation
export const canViewReservation = (reservation: AbilitiesObject<ReservationAbility>) => checkAbility(reservation, ReservationAbility.VIEW);
export const canUpdateReservation = (reservation: AbilitiesObject<ReservationAbility>) => checkAbility(reservation, ReservationAbility.UPDATE);
export const canDeleteReservation = (reservation: AbilitiesObject<ReservationAbility>) => checkAbility(reservation, ReservationAbility.DELETE);
export const canDownloadInvoiceReservation = (reservation: AbilitiesObject<ReservationAbility>) => checkAbility(reservation, ReservationAbility.DOWNLOAD);
export const canSendInvoiceReservation = (reservation: AbilitiesObject<ReservationAbility>) => checkAbility(reservation, ReservationAbility.SEND);
export const canGenerateReservationInvoice = (reservation: AbilitiesObject<ReservationAbility>) => checkAbility(reservation, ReservationAbility.GENERATE);

// Category
export const canViewCategory = (category: AbilitiesObject<CategoryAbility>) => checkAbility(category, CategoryAbility.VIEW);
export const canUpdateCategory = (category: AbilitiesObject<CategoryAbility>) => checkAbility(category, CategoryAbility.UPDATE);
export const canDeleteCategory = (category: AbilitiesObject<CategoryAbility>) => checkAbility(category, CategoryAbility.DELETE);
export const canCreateCategory = (category: AbilitiesObject<CategoryAbility>) => checkAbility(category, CategoryAbility.CREATE);

// Voucher
export const canViewVoucher = (voucher: AbilitiesObject<VoucherAbility>) => checkAbility(voucher, VoucherAbility.VIEW);
export const canUpdateVoucher = (voucher: AbilitiesObject<VoucherAbility>) => checkAbility(voucher, VoucherAbility.UPDATE);
export const canDeleteVoucher = (voucher: AbilitiesObject<VoucherAbility>) => checkAbility(voucher, VoucherAbility.DELETE);
export const canGenerateVoucherInvoice = (voucher: AbilitiesObject<VoucherAbility>) => checkAbility(voucher, VoucherAbility.GENERATE_INVOICE);
export const canSendVoucherInvoice = (voucher: AbilitiesObject<VoucherAbility>) => checkAbility(voucher, VoucherAbility.SEND_INVOICE);
export const canDownloadVoucherInvoice = (voucher: AbilitiesObject<VoucherAbility>) => checkAbility(voucher, VoucherAbility.DOWNLOAD_INVOICE);
export const canDownloadVoucher = (voucher: AbilitiesObject<VoucherAbility>) => checkAbility(voucher, VoucherAbility.DOWNLOAD_VOUCHER);
export const canSendVoucher = (voucher: AbilitiesObject<VoucherAbility>) => checkAbility(voucher, VoucherAbility.SEND_VOUCHER);

// Abilities check plop - don't remove









