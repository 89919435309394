import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { StateReducer } from "src/app/types/redux.types";
import { AdminAvailability, DetailedReservation, Reservation } from "src/app/types/api/reservation.types";
import { initialStateReducer, mergeStateReducers } from "src/app/utils/redux";
import { isNotNull } from "src/app/utils/typeguards";
import { DateTime } from "luxon";
import { Room } from "src/app/types/api/room.types";
import { Venue } from "src/app/types/api/venue.types";
import { Product } from "src/app/types/api/product.types";
import { DiscountCode } from "src/app/types/api/discountCode.types";
import { SimpleVoucher } from "src/app/types/api/voucher.types";

const reservationsSelector = (state: RootState) => state.reservation.reservations;
const adminDateReservationsSelector = (state: RootState) => state.reservation.adminDateReservations;
const singleReservationsSelector = (state: RootState) => state.reservation.singleReservation;
const adminRoomAvailabilitiesSelector = (state: RootState) => state.reservation.adminRoomAvailabilities;
const adminRoomsSelector = (state: RootState) => state.room.adminRooms;
const adminVenuesSelector = (state: RootState) => state.venue.adminVenues;
const adminProductsSelector = (state: RootState) => state.product.adminProducts;
const discountCodesSelector = (state: RootState) => state.discountCode.discountCodes;
const vouchersSelector = (state: RootState) => state.voucher.paidVouchers;

export const getReservationById = createSelector(
	[
		singleReservationsSelector,
		(_, { reservationId }: { reservationId: number }) => reservationId,
	],
	(singleReservations, reservationId): StateReducer<DetailedReservation> => {
		const reservation = singleReservations.find(reservation => reservation.id === reservationId);
		if (isNotNull(reservation)) {
			return reservation.reducer;
		} else {
			return initialStateReducer as StateReducer<DetailedReservation>;
		}
	},
);

export const getReservationsByDate = createSelector(
	[
		adminDateReservationsSelector,
		(_, { date }: { date: string }) => date,
	],
	(singleReservations, date): StateReducer<Reservation[]> => {
		const reservations = singleReservations.find(reservation => reservation.id === date);
		if (isNotNull(reservations)) {
			return reservations.reducer;
		} else {
			return initialStateReducer as StateReducer<Reservation[]>;
		}
	},
);

export const getAdminRoomAvailabilities = createSelector(
	[
		adminRoomAvailabilitiesSelector,
		(_, { roomId, date }: { roomId: number, date: DateTime }) => `${ roomId }-${ date.toFormat("yyyy-MM-dd") }`,
	],
	(roomAvailabilities, availabilityId) => {
		const availabilities = roomAvailabilities.find(availabilities => availabilities.id === availabilityId);
		if (isNotNull(availabilities)) {
			return availabilities.reducer;
		} else {
			return initialStateReducer as StateReducer<AdminAvailability[]>;
		}
	},
);

export const getRoomsWithReducers = createSelector(
	[
		adminRoomsSelector,
		adminVenuesSelector,
		adminProductsSelector,
		discountCodesSelector,
		vouchersSelector,
	],
	(rooms, venues, products, discountCodes, vouchers) => mergeStateReducers<{
		rooms: Room[]
		venues: Venue[]
		products: Product[]
		discountCodes: DiscountCode[]
		vouchers: SimpleVoucher[]
	}>(
		[ rooms, venues, products, discountCodes, vouchers, ],
		(rooms, venues, products, discountCodes, vouchers) => ({
			rooms,
			venues,
			products,
			discountCodes,
			vouchers,
		})
	)
);

export const getReservationsReducers = createSelector(
	[
		reservationsSelector,
		adminRoomsSelector,
		adminVenuesSelector,
		adminProductsSelector,
		discountCodesSelector,
		vouchersSelector,
	],
	(reservations, rooms, venues, products, discountCodes, vouchers) => mergeStateReducers<{
		reservations: Reservation[]
		rooms: Room[]
		venues: Venue[]
		products: Product[]
		discountCodes: DiscountCode[]
		vouchers: SimpleVoucher[]
	}>(
		[ reservations, rooms, venues, products, discountCodes, vouchers ],
		(reservations, rooms, venues, products, discountCodes, vouchers) => ({
			reservations,
			rooms,
			venues,
			products,
			discountCodes,
			vouchers,
		})
	)
)
