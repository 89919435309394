/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Button, Tooltip } from "flowbite-react";
import { MouseEvent } from "react";
import { HiOutlineDocumentDownload } from "react-icons/hi";

type Props = {
	ability: boolean
	onDownload: (e: MouseEvent<HTMLButtonElement>) => void
	link: string
}

function DownloadInvoiceCell(props: Props) {

	const {
		ability,
		onDownload,
		link,
	} = props;

	return (
		<div className="flex gap-2 items-center sm:justify-end">
			{
				ability &&
                <Tooltip content="Pobierz fakturę">
                    <Button
                        href={ link }
                        target="_blank"
                        onClick={ (e: MouseEvent<HTMLButtonElement>) => {
							e.stopPropagation();
							onDownload(e);
						} }
                        color="gray-outline"
                        size="sm"
                        className="p-0 [&>span]:p-2"
                        label={ <div>Pobierz Fakturę</div> }
                    >
                        <HiOutlineDocumentDownload className="w-5 h-5"/>
                    </Button>
                </Tooltip>
			}
		</div>
	);
}

export default DownloadInvoiceCell;
