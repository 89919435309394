/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Button, Tooltip } from "flowbite-react";
import { MouseEvent } from "react";
import { HiOutlinePaperAirplane } from "react-icons/hi";

type Props = {
	ability: boolean
	onSend: (e: MouseEvent<HTMLButtonElement>) => void
}

function SendInvoiceCell(props: Props) {

	const {
		ability,
		onSend,
	} = props;

	return (
		<div className="flex gap-2 items-center sm:justify-end">
			{
				ability &&
                <Tooltip content="Wyślij fakturę">
                    <Button
                        onClick={ (e: MouseEvent<HTMLButtonElement>) => {
							e.stopPropagation();
							onSend(e);
						} }
                        color="gray-outline"
                        size="sm"
                        className="p-0 [&>span]:p-2"
                    >
                        <HiOutlinePaperAirplane className="w-5 h-5"/>
                    </Button>
                </Tooltip>
			}
		</div>
	);
}

export default SendInvoiceCell;
