/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ArrayStateReducer, DataState, ErrorState, LoadingState, StateReducer } from "src/app/types/redux.types";
import { deleteObject, handleBasicActions, handleBasicActionsForArray, initialStateReducer, replaceOrPushObject, replaceStateReducer } from "src/app/utils/redux";
import moment from "moment";
import { DetailedVoucher, SimpleVoucher } from "src/app/types/api/voucher.types";
import { createVoucherAsync, deleteVoucherByIdAsync, fetchPaidVouchersAsync, fetchVoucherByIdAsync, fetchVouchersAsync, generateVoucherAsync, generateVoucherInvoiceAsync, sendVoucherAsync, sendVoucherInvoiceAsync, updateVoucherAsync } from "src/app/store/features/voucher/voucher.actions";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";

const reducer = combineReducers({
    vouchers: createReducer(initialStateReducer as StateReducer<SimpleVoucher[]>)
        .handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchVouchersAsync.request, fetchVouchersAsync.success, fetchVouchersAsync.failure ], handleBasicActions(fetchVouchersAsync))
		.handleAction([ deleteVoucherByIdAsync.success ], (state, action) => {
			if (state.dataState === DataState.PRESENT) {
				return {
					...state,
					data: deleteObject(state.data, (a) => a.id === action.payload.data.id),
				};
			} else {
				return state;
			}
		})
		.handleAction([ createVoucherAsync.success, updateVoucherAsync.success, generateVoucherInvoiceAsync.success, generateVoucherAsync.success, sendVoucherInvoiceAsync.success, sendVoucherAsync.success ], (state, action) => {
			if (state.dataState === DataState.NOT_PRESENT) return state;

			return {
				...state,
				data: replaceOrPushObject(state.data, action.payload.data, (a, b) => a.id === b.id),
			};
		}),
	paidVouchers: createReducer(initialStateReducer as StateReducer<SimpleVoucher[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchPaidVouchersAsync.request, fetchPaidVouchersAsync.success, fetchPaidVouchersAsync.failure ], handleBasicActions(fetchPaidVouchersAsync))
		.handleAction([ deleteVoucherByIdAsync.success ], (state, action) => {
			if (state.dataState === DataState.PRESENT) {
				return {
					...state,
					data: deleteObject(state.data, (a) => a.id === action.payload.data.id),
				};
			} else {
				return state;
			}
		})
		.handleAction([ createVoucherAsync.success, updateVoucherAsync.success, generateVoucherInvoiceAsync.success, generateVoucherAsync.success, sendVoucherInvoiceAsync.success, sendVoucherAsync.success ], (state, action) => {
			if (state.dataState === DataState.NOT_PRESENT) return state;

			return {
				...state,
				data: replaceOrPushObject(state.data, action.payload.data, (a, b) => a.id === b.id),
			};
		}),
	singleVoucher: createReducer([] as ArrayStateReducer<DetailedVoucher>)
        .handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([ fetchVoucherByIdAsync.request, fetchVoucherByIdAsync.success, fetchVoucherByIdAsync.failure ], handleBasicActionsForArray(fetchVoucherByIdAsync))
		.handleAction([ updateVoucherAsync.success ],
			(state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.id,
					stateItem => ({
						id: stateItem.id,
						reducer: {
							dataState: DataState.PRESENT,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.NOT_PRESENT,
							fetchedAt: moment(),
							data: action.payload.data,
						},
					}),
				)),
});

export default reducer;
