/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { fetchVouchersAsync } from "src/app/store/features/voucher/voucher.actions";
import VoucherListContainer from "src/app/containers/Voucher/VoucherList.container";
import { uiCreateVoucher, uiDeleteVoucher, uiGenerateVoucherInvoice, uiSendVoucher, uiSendVoucherInvoice, uiUpdateVoucher } from "src/app/store/features/ui/voucher/ui.voucher.actions";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function VoucherListView(props: Props) {

	const {
		vouchers,
		isGeneratingInvoice,
		isDeleting,
		isSendingInvoice,
		fetchVouchers,
		createVoucher,
		updateVoucher,
		deleteVoucher,
		generateInvoice,
		sendInvoice,
		sendVoucher,
		isSendingVoucher,
	} = props;

	return (
		<CachedThenFreshStrategy
			request={ fetchVouchers }
			state={ vouchers }
		>
			{
				vouchers =>
					<VoucherListContainer
						vouchers={ vouchers }
						onCreate={ createVoucher }
						onUpdate={ updateVoucher }
						onDelete={ deleteVoucher }
						onGenerateInvoice={ generateInvoice }
						onSendInvoice={ sendInvoice }
						isGeneratingInvoice={ isGeneratingInvoice }
						isDeleting={ isDeleting }
						isSendingInvoice={ isSendingInvoice }
						onSendVoucher={ sendVoucher }
						isSendingVoucher={ isSendingVoucher }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	vouchers: state.voucher.vouchers,
	isGeneratingInvoice: didLoadingRecordExist(state, { loadableType: LoadableType.GENERATE_VOUCHER_INVOICE }),
	isDeleting: didLoadingRecordExist(state, { loadableType: LoadableType.DELETE_VOUCHER }),
	isSendingInvoice: didLoadingRecordExist(state, { loadableType: LoadableType.SEND_VOUCHER_INVOICE }),
	isSendingVoucher: didLoadingRecordExist(state, { loadableType: LoadableType.SEND_VOUCHER }),
});

const mapDispatchToProps = {
	fetchVouchers: fetchVouchersAsync.request,
	createVoucher: uiCreateVoucher,
	updateVoucher: uiUpdateVoucher,
	deleteVoucher: uiDeleteVoucher,
	generateInvoice: uiGenerateVoucherInvoice,
	sendInvoice: uiSendVoucherInvoice,
	sendVoucher: uiSendVoucher,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherListView);