/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedVoucher } from "src/app/types/api/voucher.types";
import { createVoucherAsync, deleteVoucherByIdAsync, fetchPaidVouchersAsync, fetchVoucherByIdAsync, fetchVouchersAsync, generateVoucherAsync, generateVoucherInvoiceAsync, purchaseVoucherAsync, sendVoucherAsync, sendVoucherInvoiceAsync, updateVoucherAsync } from "src/app/store/features/voucher/voucher.actions";

export const fetchVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchVouchersAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/admin/vouchers`,
				method: "GET",
				withScope: true,
				onSuccess: fetchVouchersAsync.success,
				onFailure: fetchVouchersAsync.failure,
			}),
		),
	);

export const fetchActiveVouchersEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchPaidVouchersAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/admin/vouchers?filter[paid_vouchers]=true`,
				method: "GET",
				withScope: true,
				onSuccess: fetchPaidVouchersAsync.success,
				onFailure: fetchPaidVouchersAsync.failure,
			}),
		),
	);

export const fetchVoucherByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchVoucherByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/vouchers/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedVoucher>) => fetchVoucherByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchVoucherByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const purchaseVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(purchaseVoucherAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/vouchers",
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: purchaseVoucherAsync.success,
				onFailure: purchaseVoucherAsync.failure,
			}),
		),
	);

export const createVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createVoucherAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/admin/vouchers",
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: createVoucherAsync.success,
				onFailure: createVoucherAsync.failure,
			}),
		),
	);

export const updateVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateVoucherAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/vouchers/${ action.payload.id }`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateVoucherAsync.success,
				onFailure: updateVoucherAsync.failure,
			}),
		),
	);

export const deleteVoucherByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteVoucherByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/vouchers/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteVoucherByIdAsync.success,
				onFailure: deleteVoucherByIdAsync.failure,
			}),
		),
	);

export const generateVoucherInvoiceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(generateVoucherInvoiceAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/vouchers/${ action.payload }/generate-invoice`,
				method: "GET",
				withScope: true,
				onSuccess: generateVoucherInvoiceAsync.success,
				onFailure: generateVoucherInvoiceAsync.failure,
			}),
		),
	);

export const sendVoucherInvoiceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(sendVoucherInvoiceAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/vouchers/${ action.payload }/send-invoice-email`,
				method: "POST",
				withScope: true,
				onSuccess: sendVoucherInvoiceAsync.success,
				onFailure: sendVoucherInvoiceAsync.failure,
			}),
		),
	);

export const generateVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(generateVoucherAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/vouchers/${ action.payload }/generate-voucher`,
				method: "GET",
				withScope: true,
				onSuccess: generateVoucherAsync.success,
				onFailure: generateVoucherAsync.failure,
			}),
		),
	);

export const sendVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(sendVoucherAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/vouchers/${ action.payload }/send-voucher-email`,
				method: "POST",
				withScope: true,
				onSuccess: sendVoucherAsync.success,
				onFailure: sendVoucherAsync.failure,
			}),
		),
	);