/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from "react";
import { FormItem } from "src/app/types/ui/form.types";
import { Nullable, SelectOption } from "src/app/types/util.types";
import GroupedSelect from "src/app/components/Form/GroupedSelect.component";

type Props = {
	discountCodesOptions: { value: string, label: string }[]
	vouchersOptions: { value: string, label: string }[]
	formItem: FormItem<Nullable<string>>
	onChange: (option: Nullable<SelectOption<string | null | undefined>>) => void
};

function SelectDiscountCodeOrVoucher(props: Props) {

	const {
		discountCodesOptions,
		vouchersOptions,
		formItem,
		onChange,
	} = props;

	const groupedOptions = [
		{
			label: "Kody rabatowe",
			options: discountCodesOptions,
		},
		{
			label: "Vouchery",
			options: vouchersOptions,
		}
	];

	return (
		<GroupedSelect
			label="Kod rabatowy / voucher"
			options={ groupedOptions }
			formItem={ formItem }
			onChange={ onChange }
			isSearchable
			isClearable
		/>
	);
}

export default (SelectDiscountCodeOrVoucher);