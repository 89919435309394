/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Nullable } from "src/app/types/util.types";
import { Form, FormHookReturnType, FormValidator } from "src/app/types/ui/form.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { DateTime } from "luxon";
import { createFormField, validateEmail, validateField, validateNullableField, validateNumberField, validatePhone } from "src/app/utils/forms";
import { DiscountCodeValidityType, SimpleDiscountCode } from "src/app/types/api/discountCode.types";
import { LocaleFromISO } from "src/app/utils/luxon";
import { SimpleRoom } from "src/app/types/api/room.types";
import { SimpleVenue } from "src/app/types/api/venue.types";
import { ReservationStatus } from "src/app/types/api/reservation.types";
import { SimpleVoucher } from "src/app/types/api/voucher.types";

export type CreateRoomReservationForm = {
	date: Nullable<string>
	startDate: Nullable<string>
	endDate: Nullable<string>
	people: string
	room: Nullable<SimpleRoom>
	name: string
	surname: string
	email: string
	phone: string
	formattedPhone: string
	reservingPerson: string
	comment: string
	isMarketingConsent: boolean
	products: AdminReservationPayloadProduct[] // Modal z grid 2-3 listą scrollowalna?, po dodaniu mamy + i - z możliwościa zmiany ilości, kosz z usunięciem
	discountCode: Nullable<string>
	isDiscountedPriceUnderflow: boolean
	isForCompany: boolean
	companyName: string
	companyNip: string
	companyAddress: string
	companyPostCode: string
	companyCity: string
	venue: Nullable<SimpleVenue>

	price: string
	discountedPrice: Nullable<string>
};

export type UpdateRoomReservationForm = {
	date: string
	startDate: Nullable<string>
	endDate: Nullable<string>
	people: string
	maxPeople: number
	name: string
	surname: string
	email: string
	phone: string
	formattedPhone: string
	reservingPerson: string
	comment: string
	isMarketingConsent: boolean
	products: AdminReservationPayloadProduct[] // Modal z grid 2-3 listą scrollowalna?, po dodaniu mamy + i - z możliwościa zmiany ilości, kosz z usunięciem
	discountCode: Nullable<string>
	isDiscountedPriceUnderflow: boolean
	isForCompany: boolean
	companyName: string
	companyNip: string
	companyAddress: string
	companyPostCode: string
	companyCity: string
	status: Nullable<ReservationStatus>

	price: string
	discountedPrice: Nullable<string>
};

export type AdminReservationPayloadProduct = {
	id: number
	quantity: number
}

export const createRoomReservationFormInitialState: Form<CreateRoomReservationForm> = {
	date: createFormField(null),
	startDate: createFormField(null),
	endDate: createFormField(null),
	people: createFormField(""),
	room: createFormField(null),
	name: createFormField(""),
	surname: createFormField(""),
	email: createFormField(""),
	phone: createFormField(""),
	formattedPhone: createFormField(""),
	reservingPerson: createFormField("", { optional: true }),
	comment: createFormField("", { optional: true }),
	isMarketingConsent: createFormField(false),
	products: createFormField([]),
	discountCode: createFormField(null),
	isDiscountedPriceUnderflow: createFormField(false),
	isForCompany: createFormField(false),
	companyName: createFormField(""),
	companyNip: createFormField(""),
	companyAddress: createFormField(""),
	companyPostCode: createFormField(""),
	companyCity: createFormField(""),
	venue: createFormField(null),
	price: createFormField(""),
	discountedPrice: createFormField(null),
};

export const updateRoomReservationFormInitialState: Form<UpdateRoomReservationForm> = {
	date: createFormField(""),
	startDate: createFormField(""),
	endDate: createFormField(""),
	people: createFormField(""),
	maxPeople: createFormField(0),
	name: createFormField(""),
	surname: createFormField(""),
	email: createFormField(""),
	phone: createFormField(""),
	formattedPhone: createFormField(""),
	reservingPerson: createFormField("", { optional: true }),
	comment: createFormField("", { optional: true }),
	isMarketingConsent: createFormField(false),
	products: createFormField([]),
	discountCode: createFormField(null),
	isDiscountedPriceUnderflow: createFormField(false),
	isForCompany: createFormField(false),
	companyName: createFormField(""),
	companyNip: createFormField(""),
	companyAddress: createFormField(""),
	companyPostCode: createFormField(""),
	companyCity: createFormField(""),
	status: createFormField(null),
	price: createFormField(""),
	discountedPrice: createFormField(null),
};

export const createRoomReservationFormValidator: FormValidator<CreateRoomReservationForm> = {
	date: (date, optional) => validateNullableField("Data", date, optional),
	startDate: (startDate, optional, form) => {
		if (isNull(startDate)) return "Godzina rozpoczęcia jest wymagana";

		if (isNotNull(form.endDate.value) && LocaleFromISO(startDate) >= LocaleFromISO(form.endDate.value)) {
			return "Nie poprawny zakres godzin";
		}
		return null;
	},
	endDate: (endDate, optional, form) => {
		if (isNull(endDate)) return "Godzina zakończenia jest wymagana";

		if (isNotNull(form.startDate.value) && LocaleFromISO(form.startDate.value) >= LocaleFromISO(endDate)) {
			return "Nie poprawny zakres godzin";
		}
		return null;
	},
	people: (people, optional, form) => validateNumberField("Ilość osób", people, optional, 1, form.room.value?.maxPeople),
	room: () => null,
	name: (name, optional) => validateField("Imię", name, optional),
	surname: (surname, optional) => validateField("Nazwisko", surname, optional),
	email: validateEmail,
	phone: validatePhone,
	formattedPhone: () => null,
	reservingPerson: (reservingPerson, optional) => validateField("Kto rezerwuje", reservingPerson, optional),
	comment: (comment, optional) => validateField("Komentarz", comment, optional),
	isMarketingConsent: () => null,
	products: () => null,
	discountCode: () => null,
	isDiscountedPriceUnderflow: () => null,
	isForCompany: () => null,
	companyName: (companyName, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("Nazwa firmy", companyName, optional);
	},
	companyNip: (companyNip, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("NIP", companyNip, optional);
	},
	companyAddress: (companyAddress, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("Adres", companyAddress, optional);
	},
	companyPostCode: (companyPostCode, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("Kod pocztowy", companyPostCode, optional);
	},
	companyCity: (companyCity, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("Miasto", companyCity, optional);
	},
	venue: () => null,
	price: () => null,
	discountedPrice: () => null,
};

export const updateRoomReservationFormValidator: FormValidator<UpdateRoomReservationForm> = {
	date: (date, optional) => validateField("Data", date, optional),
	startDate: (startDate, optional, form) => {
		if (isNull(startDate)) return "Godzina rozpoczęcia jest wymagana";

		if (isNotNull(form.endDate.value) && LocaleFromISO(startDate) >= LocaleFromISO(form.endDate.value)) {
			return "Nie poprawny zakres godzin";
		}
		return null;
	},
	endDate: (endDate, optional, form) => {
		if (isNull(endDate)) return "Godzina zakończenia jest wymagana";

		if (isNotNull(form.startDate.value) && LocaleFromISO(form.startDate.value) >= LocaleFromISO(endDate)) {
			return "Nie poprawny zakres godzin";
		}
		return null;
	},
	people: (people, optional, form) => validateNumberField("Ilość osób", people, optional, 1, form.maxPeople.value),
	maxPeople: () => null,
	name: (name, optional) => validateField("Imię", name, optional),
	surname: (surname, optional) => validateField("Nazwisko", surname, optional),
	email: validateEmail,
	phone: validatePhone,
	formattedPhone: () => null,
	reservingPerson: (reservingPerson, optional) => validateField("Kto rezerwuje", reservingPerson, optional),
	comment: (comment, optional) => validateField("Komentarz", comment, optional),
	isMarketingConsent: () => null,
	products: () => null,
	discountCode: () => null,
	isDiscountedPriceUnderflow: () => null,
	isForCompany: () => null,
	companyName: (companyName, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("Nazwa firmy", companyName, optional);
	},
	companyNip: (companyNip, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("NIP", companyNip, optional);
	},
	companyAddress: (companyAddress, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("Adres", companyAddress, optional);
	},
	companyPostCode: (companyPostCode, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("Kod pocztowy", companyPostCode, optional);
	},
	companyCity: (companyCity, optional, form) => {
		if (!form.isForCompany.value) return null;

		return validateField("Miasto", companyCity, optional);
	},
	status: (status, optional) => validateNullableField("Status", status, optional),
	price: () => null,
	discountedPrice: () => null,
};

export const getRoomReservationDiscountCodeOptions = (discountCodes: SimpleDiscountCode[]) => discountCodes.filter(discountCode => {
	if (!discountCode.isActive) return false;

	if (discountCode.validityType === DiscountCodeValidityType.MAX_USAGES) {
		return (discountCode.usages ?? 0) < +discountCode.maxUsages;
	} else if (discountCode.validityType === DiscountCodeValidityType.EXPIRES_AT) {
		return LocaleFromISO(discountCode.expiresAt) > LocaleFromISO(DateTime.now().toISO());
	} else {
		return true;
	}
}).map(discountCode => ({
	value: discountCode.code,
	label: discountCode.code,
}));

export const getRoomReservationVoucherOptions = (vouchers: SimpleVoucher[]) => vouchers.filter(voucher => {
	return voucher.isActive;
}).map(voucher => ({
	value: voucher.discountCodeCode,
	label: voucher.discountCodeCode,
}));

export const getAllProductsCount = (products: AdminReservationPayloadProduct[]) => products.reduce<number>((prev, current) => prev + current.quantity, 0);

export const handleRoomReservationDiscountCodeChange = <T extends { discountCode: Nullable<string> }>({ handleChange, handleBlur }: FormHookReturnType<T>, discountCode: Nullable<string>) => {
	handleChange("discountCode", discountCode);
	handleBlur("discountCode");
};

export const onRoomReservationProductPlus = <T extends { products: AdminReservationPayloadProduct[] }>({ form, handleChange }: FormHookReturnType<T>, productId: number) => {
	const products: AdminReservationPayloadProduct[] = form.products.value.map(formProduct => {
		if (formProduct.id !== productId) return formProduct;
		return {
			id: productId,
			quantity: formProduct.quantity + 1,
		};
	});
	handleChange("products", products);
};

export const onRoomReservationProductMinus = <T extends { products: AdminReservationPayloadProduct[] }>({ form, handleChange }: FormHookReturnType<T>, productId: number) => {
	const products: AdminReservationPayloadProduct[] = form.products.value.map(formProduct => {
		if (formProduct.id !== productId) return formProduct;
		return {
			id: productId,
			quantity: formProduct.quantity === 0 ? 0 : (formProduct.quantity - 1),
		};
	});
	handleChange("products", products);
};
