import { RouteObject } from "react-router/dist/lib/context";
import DefaultRoute from "src/app/hoc/routes/Default.route";
import AuthRoute from "src/app/hoc/routes/Auth.route";
import authRoutes from "src/app/utils/routes/auth.routes";
import PrivateRoute from "src/app/hoc/routes/Private.route";
import privateRoutes from "src/app/utils/routes/private.routes";
import NotFoundView from "src/app/views/Utils/NotFound.view";
import CheckQueryParamsView from "src/app/views/Utils/CheckQueryParams.view";
import ImpersonateUserView from "src/app/views/Utils/ImpersonateUser.view";
import { lazy } from "react";
import purchasingProcessRoutes from "src/app/utils/routes/purchasingProcess.routes";
import { ReservationRoute } from "src/app/types/api/reservation.types";
import ConfirmationView from "src/app/views/PurchasingProcess/PurchasingProcessConfirmation.view";
import FailedView from "src/app/views/PurchasingProcess/PurchasingProcessFailed.view";
import ProcessingView from "src/app/views/PurchasingProcess/PurchasingProcessProcessing.view";
import PurchasingResultLayout from "src/app/hoc/layouts/PurchasingResult.layout";
import PurchasingResultBreadcrumbsLayout from "src/app/hoc/layouts/PurchasingResultBreadcrumbs.layout";
import PurchasingProcess from "src/app/hoc/layouts/PurchasingProcess.layout";
import VoucherLayout from "src/app/hoc/layouts/PurchaseVoucher.layout";
import PurchaseVoucherView from "src/app/views/Voucher/PurchaseVoucher.view";
import PurchaseVoucherConfirmationView from "src/app/views/Voucher/PurchaseVoucherConfirmation.view";
import PurchaseVoucherFailedView from "src/app/views/Voucher/PurchaseVoucherFailed.view";
import PurchaseVoucherProcessingView from "src/app/views/Voucher/PurchaseVoucherProcessing.view";

const AuthLayout = lazy(() => import("src/app/hoc/layouts/Auth.layout"));
const DashboardLayout = lazy(() => import("src/app/hoc/layouts/Dashboard.layout"));

export const appRoutes: RouteObject[] = ([
	{
		path: "/panel",
		element: <DefaultRoute/>,
	},
	{
		element: <PurchasingResultLayout/>,
		children:
			[
				{
					element: <PurchasingResultBreadcrumbsLayout/>,
					children:
						[
							{
								path: ReservationRoute.CONFIRMATION,
								element: <ConfirmationView/>,
							},
							{
								path: ReservationRoute.FAILED,
								element: <FailedView/>,
							},
							{
								path: ReservationRoute.PROCESSING,
								element: <ProcessingView/>,
							},

						],
				},
			],
	},
	{
		element: <VoucherLayout />,
		children: [
			{
				path: "/voucher",
				element: <PurchaseVoucherView />
			},
			{
				path: "/confirmation-voucher",
				element: <PurchaseVoucherConfirmationView />
			},
			{
				path: "/failed-voucher",
				element: <PurchaseVoucherFailedView />
			},
			{
				path: "/processing-voucher",
				element: <PurchaseVoucherProcessingView />
			}
		],
	},
	{
		element: <PurchasingProcess/>,
		children: purchasingProcessRoutes,
	},
	{
		element: <AuthRoute/>,
		children: [
			{
				element: <AuthLayout/>,
				children: authRoutes,
			},
		],
	},
	{
		element: <PrivateRoute/>,
		children: [
			{
				element: <DashboardLayout/>,
				children: [
					{
						path: "/panel/organization/:scopeOrganizationId",
						children: privateRoutes,
					},
					{
						path: "/panel/impersonate-user",
						element: <ImpersonateUserView/>,
					},
					{
						// Not found page redirect to /login when isAuthorized === false
						path: "*",
						element: <NotFoundView/>,
					},
				],
			},
		],
	},
	{
		path: "/route/:action",
		element: <CheckQueryParamsView/>,
	},
]);
