/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DiscountCode, DiscountCodeType, DiscountCodeValidityType, UpdateDiscountCodePayload } from "src/app/types/api/discountCode.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { Nullable } from "src/app/types/util.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateFutureDate, validateNumberField } from "src/app/utils/forms";
import { useEffect } from "react";
import useForm from "src/app/utils/hooks/useForm";
import { DateTime } from "luxon";
import { isNull } from "src/app/utils/typeguards";
import { Button, Checkbox, Label, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import Select from "src/app/components/Form/Select.component";
import { mapEnumToSelectOptions } from "src/app/utils/helpers";
import { discountCodeTypeDictionary, discountCodeValidityTypeDictionary } from "src/app/utils/constants/dictionaries";
import DatePicker from "src/app/components/Form/DatePicker.component";
import { LocaleFromISO } from "src/app/utils/luxon";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	discountCode: Nullable<DiscountCode>
	onUpdate: (payload: UpdateDiscountCodePayload) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type EditDiscountCodeForm = {
	type: DiscountCodeType
	codeValue: string
	validityType: DiscountCodeValidityType
	expiresAt: Nullable<Date>
	maxUsages: string
	isActive: boolean
}

const validator: FormValidator<EditDiscountCodeForm> = {
	type: () => null,
	codeValue: (codeValue, optional, form) => {
		if (form.type.value === DiscountCodeType.PERCENTAGE) {
			return validateNumberField("Wielkość kodu", codeValue, optional, 0, 100);
		} else {
			return validateNumberField("Wielkość kodu", codeValue, optional);
		}
	},
	validityType: () => null,
	expiresAt: (expiresAt, optional, form) => {
		if (form.validityType.value !== DiscountCodeValidityType.EXPIRES_AT) return null;

		return validateFutureDate("Data wygaśnięcia", expiresAt, optional);
	},
	maxUsages: (maxUsages, optional, form) => {
		if (form.validityType.value !== DiscountCodeValidityType.MAX_USAGES) return null;

		return validateNumberField("Ilość użyć", maxUsages, optional);
	},
	isActive: () => null,
};

function EditDiscountCodeModal(props: Props) {

	const {
		isOpen,
		handleClose,
		discountCode,
		onUpdate,
		isUpdating,
	} = props;

	const _handleSubmit = (values: EditDiscountCodeForm) => {
		if (isNull(discountCode)) return;

		if (values.type === DiscountCodeType.AMOUNT) {
			if (values.validityType === DiscountCodeValidityType.EXPIRES_AT) {
				if (isNull(values.expiresAt)) return;

				onUpdate({
					id: discountCode.id,
					type: DiscountCodeType.AMOUNT,
					isActive: values.isActive,
					amount: values.codeValue,
					validityType: DiscountCodeValidityType.EXPIRES_AT,
					expiresAt: values.expiresAt.toISOString(),
				});
			} else {
				onUpdate({
					id: discountCode.id,
					type: DiscountCodeType.AMOUNT,
					isActive: values.isActive,
					amount: values.codeValue,
					validityType: DiscountCodeValidityType.MAX_USAGES,
					maxUsages: +values.maxUsages,
				});
			}
		} else {
			if (values.validityType === DiscountCodeValidityType.EXPIRES_AT) {
				if (isNull(values.expiresAt)) return;

				onUpdate({
					id: discountCode.id,
					type: DiscountCodeType.PERCENTAGE,
					isActive: values.isActive,
					percentage: +values.codeValue,
					validityType: DiscountCodeValidityType.EXPIRES_AT,
					expiresAt: values.expiresAt.toISOString(),
				});
			} else {
				onUpdate({
					id: discountCode.id,
					type: DiscountCodeType.PERCENTAGE,
					isActive: values.isActive,
					percentage: +values.codeValue,
					validityType: DiscountCodeValidityType.MAX_USAGES,
					maxUsages: +values.maxUsages,
				});
			}
		}
		handleClose();
	};

	const _getInitialState = () => {
		const codeValue = (discountCode?.type === DiscountCodeType.PERCENTAGE ? (discountCode?.percentage ?? "").toString() : discountCode?.amount) ?? "";
		const expiresAt = discountCode?.validityType === DiscountCodeValidityType.EXPIRES_AT ? LocaleFromISO(discountCode?.expiresAt)?.toJSDate() : null;
		const maxUsages = discountCode?.validityType === DiscountCodeValidityType.MAX_USAGES ? (discountCode?.maxUsages ?? "").toString() : "";

		return {
			type: createFormField(discountCode?.type ?? DiscountCodeType.AMOUNT),
			codeValue: createFormField(codeValue),
			validityType: createFormField(discountCode?.validityType ?? DiscountCodeValidityType.EXPIRES_AT),
			expiresAt: createFormField(expiresAt),
			maxUsages: createFormField(maxUsages),
			isActive: createFormField(discountCode?.isActive ?? true),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isUpdating) setForm(_getInitialState());
	}, [ isOpen, isUpdating ]);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ discountCode ]);

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isUpdating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				{ `Edytuj kod rabatowy ${ discountCode?.code }` }
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div className="space-y-3">
						<Select
							label="Typ kodu"
							options={ mapEnumToSelectOptions(DiscountCodeType, discountCodeTypeDictionary) }
							formItem={ form.type }
							onChange={ option => {
								if (isNull(option)) return;
								handleChange("type", option.value);
								handleBlur("type");
							} }
							isSearchable={ false }
							isClearable={ false }
						/>
						<Input
							formItem={ form.codeValue }
							label="Wielkość zniżki"
							name="codeValue"
							inputProps={ {
								type: "number",
								rightIcon: () =>
									form.type.value === DiscountCodeType.AMOUNT ?
										<span className="pr-5">zł</span>
										:
										<span className="pr-5">%</span>,
								onChange: (e) => handleChange("codeValue", e.target.value),
								onBlur: () => handleBlur("codeValue"),
							} }
						/>
						<Select
							label="Rodzaj wygaśnięcia"
							options={ mapEnumToSelectOptions(DiscountCodeValidityType, discountCodeValidityTypeDictionary).filter(option => option.value !== DiscountCodeValidityType.VOUCHER) }
							formItem={ form.validityType }
							onChange={ option => {
								if (isNull(option)) return;
								handleChange("validityType", option.value);
								handleBlur("validityType");
							} }
							isSearchable={ false }
							isClearable={ false }
						/>
						{
							form.validityType.value === DiscountCodeValidityType.EXPIRES_AT &&
                            <DatePicker
                                label="Data wygaśniecia"
                                className="flex-grow"
                                formItem={ form.expiresAt }
                                datepickerProps={ {
									minDate: LocaleFromISO(DateTime.now().toISO())?.toJSDate(),
								} }
                                onChange={ date => handleChange("expiresAt", date) }
                                onBlur={ () => handleBlur("expiresAt") }
                            />
						}
						{
							form.validityType.value === DiscountCodeValidityType.MAX_USAGES &&
                            <Input
                                formItem={ form.maxUsages }
                                label="Ilość użyć"
                                name="maxUsages"
                                inputProps={ {
									type: "number",
									onChange: (e) => handleChange("maxUsages", e.target.value),
									onBlur: () => handleBlur("maxUsages"),
								} }
                            />
						}
						<div
							className="flex items-center gap-x-3"
							onClick={ () => handleChange("isActive", !form.isActive.value) }
						>
							<Checkbox
								name="isActive"
								checked={ form.isActive.value }
								onChange={ () => undefined }
							/>
							<Label htmlFor="isActive">
								Aktywny
							</Label>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isUpdating }
					>
						Zapisz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isUpdating: didLoadingRecordExist(state, { loadableId: props.discountCode?.id ?? -1, loadableType: LoadableType.UPDATE_DISCOUNT_CODE }),
});

export default connect(mapStateToProps)(EditDiscountCodeModal);
