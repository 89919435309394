/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CreateDiscountCodePayload, DiscountCodeType, DiscountCodeValidityType } from "src/app/types/api/discountCode.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField, validateFutureDate, validateNumberField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Checkbox, Label, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import { isNull } from "src/app/utils/typeguards";
import Select from "src/app/components/Form/Select.component";
import { mapEnumToSelectOptions } from "src/app/utils/helpers";
import { discountCodeTypeDictionary, discountCodeValidityTypeDictionary } from "src/app/utils/constants/dictionaries";
import { Nullable } from "src/app/types/util.types";
import DatePicker from "src/app/components/Form/DatePicker.component";
import { DateTime } from "luxon";
import { LocaleFromISO } from "src/app/utils/luxon";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		isOpen: boolean
		handleClose: () => void
		onSave: (payload: CreateDiscountCodePayload) => void
	};

type CreateDiscountCodeForm = {
	generateCustomCode: boolean
	code: string
	type: DiscountCodeType
	codeValue: string
	validityType: DiscountCodeValidityType
	expiresAt: Nullable<Date>
	maxUsages: string
	isActive: boolean
}

const validator: FormValidator<CreateDiscountCodeForm> = {
	generateCustomCode: () => null,
	code: (code, optional, form) => validateField("kod", code, form.generateCustomCode.value),
	type: () => null,
	codeValue: (codeValue, optional, form) => {
		if (form.type.value === DiscountCodeType.PERCENTAGE) {
			return validateNumberField("Wielkość kodu", codeValue, optional, 0, 100);
		} else {
			return validateNumberField("Wielkość kodu", codeValue, optional);
		}
	},
	validityType: () => null,
	expiresAt: (expiresAt, optional, form) => {
		if (form.validityType.value !== DiscountCodeValidityType.EXPIRES_AT) return null;

		return validateFutureDate("Data wygaśnięcia", expiresAt, optional);
	},
	maxUsages: (maxUsages, optional, form) => {
		if (form.validityType.value !== DiscountCodeValidityType.MAX_USAGES) return null;

		return validateNumberField("Ilość użyć", maxUsages, optional);
	},
	isActive: () => null,
};

function CreateDiscountCodeModal(props: Props) {

	const {
		isOpen,
		handleClose,
		onSave,
		isCreating,
	} = props;

	const _handleSubmit = (values: CreateDiscountCodeForm) => {
		if (values.type === DiscountCodeType.AMOUNT) {
			if (values.validityType === DiscountCodeValidityType.EXPIRES_AT) {
				if (isNull(values.expiresAt)) return;

				onSave({
					code: values.generateCustomCode ? undefined : values.code,
					type: DiscountCodeType.AMOUNT,
					isActive: values.isActive,
					amount: values.codeValue,
					validityType: DiscountCodeValidityType.EXPIRES_AT,
					expiresAt: values.expiresAt.toISOString(),
				});
			} else {
				onSave({
					code: values.generateCustomCode ? undefined : values.code,
					type: DiscountCodeType.AMOUNT,
					isActive: values.isActive,
					amount: values.codeValue,
					validityType: DiscountCodeValidityType.MAX_USAGES,
					maxUsages: values.maxUsages,
				});
			}
		} else {
			if (values.validityType === DiscountCodeValidityType.EXPIRES_AT) {
				if (isNull(values.expiresAt)) return;

				onSave({
					code: values.generateCustomCode ? undefined : values.code,
					type: DiscountCodeType.PERCENTAGE,
					isActive: values.isActive,
					percentage: +values.codeValue,
					validityType: DiscountCodeValidityType.EXPIRES_AT,
					expiresAt: values.expiresAt.toISOString(),
				});
			} else {
				onSave({
					code: values.generateCustomCode ? undefined : values.code,
					type: DiscountCodeType.PERCENTAGE,
					isActive: values.isActive,
					percentage: +values.codeValue,
					validityType: DiscountCodeValidityType.MAX_USAGES,
					maxUsages: values.maxUsages,
				});
			}
		}
		handleClose();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useForm({
		generateCustomCode: createFormField(true),
		code: createFormField(""),
		type: createFormField(DiscountCodeType.AMOUNT),
		codeValue: createFormField(""),
		validityType: createFormField(DiscountCodeValidityType.MAX_USAGES),
		expiresAt: createFormField(null),
		maxUsages: createFormField(""),
		isActive: createFormField(true),
	}, validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isCreating) resetForm();
	}, [ isOpen, isCreating ]);

	return (
		<Modal
			show={ isOpen || isCreating }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isCreating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Dodaj nowy kod rabatowy
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div className="space-y-3">
						<div className="space-y-1">
							<Input
								formItem={ form.code }
								label="Kod"
								name="code"
								inputProps={ {
									type: "text",
									disabled: form.generateCustomCode.value,
									onChange: (e) => handleChange("code", e.target.value),
									onBlur: () => handleBlur("code"),
								} }
							/>
							<div
								className="flex items-center gap-x-3"
								onClick={ () => {
									handleChange("generateCustomCode", !form.generateCustomCode.value);
									handleChange("code", "");
									form.code.touched && handleBlur("code");
								} }
							>
								<Checkbox
									name="generateCustomCode"
									checked={ form.generateCustomCode.value }
									onChange={ () => undefined }
								/>
								<Label htmlFor="generateCustomCode">
									Wygeneruj losowy kod
								</Label>
							</div>
						</div>
						<Select
							label="Typ kodu"
							options={ mapEnumToSelectOptions(DiscountCodeType, discountCodeTypeDictionary) }
							formItem={ form.type }
							onChange={ option => {
								if (isNull(option)) return;
								handleChange("type", option.value);
								handleBlur("type");
							} }
							isSearchable={ false }
							isClearable={ false }
						/>
						<Input
							formItem={ form.codeValue }
							label="Wielkość zniżki"
							name="codeValue"
							inputProps={ {
								type: "number",
								rightIcon: () =>
									form.type.value === DiscountCodeType.AMOUNT ?
										<span className="pr-5">zł</span>
										:
										<span className="pr-5">%</span>,
								onChange: (e) => handleChange("codeValue", e.target.value),
								onBlur: () => handleBlur("codeValue"),
							} }
						/>
						<Select
							label="Rodzaj wygaśnięcia"
							options={ mapEnumToSelectOptions(DiscountCodeValidityType, discountCodeValidityTypeDictionary).filter(option => option.value !== DiscountCodeValidityType.VOUCHER) }
							formItem={ form.validityType }
							onChange={ option => {
								if (isNull(option)) return;
								handleChange("validityType", option.value);
								handleBlur("validityType");
							} }
							isSearchable={ false }
							isClearable={ false }
						/>
						{
							form.validityType.value === DiscountCodeValidityType.EXPIRES_AT &&
                            <DatePicker
                                label="Data wygaśniecia"
                                className="flex-grow"
                                formItem={ form.expiresAt }
                                datepickerProps={ {
									minDate: LocaleFromISO(DateTime.now().toISO())?.toJSDate(),
								} }
                                onChange={ date => handleChange("expiresAt", date) }
                                onBlur={ () => handleBlur("expiresAt") }
                            />
						}
						{
							form.validityType.value === DiscountCodeValidityType.MAX_USAGES &&
                            <Input
                                formItem={ form.maxUsages }
                                label="Ilość użyć"
                                name="maxUsages"
                                inputProps={ {
									type: "number",
									onChange: (e) => handleChange("maxUsages", e.target.value),
									onBlur: () => handleBlur("maxUsages"),
								} }
                            />
						}
						<div
							className="flex items-center gap-x-3"
							onClick={ () => handleChange("isActive", !form.isActive.value) }
						>
							<Checkbox
								name="isActive"
								checked={ form.isActive.value }
								onChange={ () => undefined }
							/>
							<Label htmlFor="isActive">
								Aktywny
							</Label>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isCreating }
					>
						Stwórz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_DISCOUNT_CODE }),
});

export default connect(mapStateToProps)(CreateDiscountCodeModal);
