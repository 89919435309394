/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import PurchaseVoucherProcessingContainer from "src/app/containers/Voucher/PurchaseVoucherProcessing.container";

function PurchaseVoucherProcessingView() {
	return (
		<PurchaseVoucherProcessingContainer/>
	);
}

export default (PurchaseVoucherProcessingView);