/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import PurchaseVoucherContainer from "src/app/containers/Voucher/PurchaseVoucher.container";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { uiPurchaseVoucher } from "src/app/store/features/ui/voucher/ui.voucher.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function PurchaseVoucherView(props: Props) {

	const {
		purchaseVoucher,
	} = props;

	return (
		<PurchaseVoucherContainer
			onPurchaseVoucher={ purchaseVoucher }
		/>
	);
}


const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = {
	purchaseVoucher: uiPurchaseVoucher,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseVoucherView);