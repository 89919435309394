/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { RouteObject } from "react-router/dist/lib/context";
import VoucherListView from "src/app/views/Voucher/VoucherList.view";

const voucherRoutes: RouteObject = {
	path: "vouchers",
	children: [
		{
			index: true,
			element: <VoucherListView/>,
		},
	],
};

export default voucherRoutes;