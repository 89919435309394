import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { StateReducer } from "src/app/types/redux.types";
import { DetailedVenue, SimpleVenue, Venue } from "src/app/types/api/venue.types";
import { initialStateReducer, mergeStateReducers } from "src/app/utils/redux";
import { isNotNull } from "src/app/utils/typeguards";
import { Product, SimpleProduct } from "src/app/types/api/product.types";
import { SimpleCategory } from "src/app/types/api/category.types";
import { DiscountCode } from "src/app/types/api/discountCode.types";
import { VenueAvailabilities } from "src/app/types/api/reservation.types";
import { getAvailabilityId } from "src/app/utils/helpers";
import { Room } from "src/app/types/api/room.types";
import { SimpleVoucher } from "src/app/types/api/voucher.types";

//admin
const singleVenuesSelector = (state: RootState) => state.venue.singleVenue;
const adminVenuesSelector = (state: RootState) => state.venue.adminVenues;
const adminRoomsSelector = (state: RootState) => state.room.adminRooms;
const venueAdminAvailabilitiesSelector = (state: RootState) => state.venue.adminVenueAvailabilities;
const discountCodesSelector = (state: RootState) => state.discountCode.discountCodes;
const vouchersSelector = (state: RootState) => state.voucher.paidVouchers;
const adminProductsSelector = (state: RootState) => state.product.adminProducts;

//client
const clientVenuesSelector = (state: RootState) => state.venue.venues;
const clientProductsSelector = (state: RootState) => state.product.products;
const clientCategoriesSelector = (state: RootState) => state.category.categories;

export const getVenueById = createSelector(
    [
        singleVenuesSelector,
        (_, venueId: number) => venueId,
    ],
    (singleVenues, venueId): StateReducer<DetailedVenue> => {
        const venue = singleVenues.find(venue => venue.id === venueId);
        if (isNotNull(venue)) {
            return venue.reducer;
        } else {
            return initialStateReducer as StateReducer<DetailedVenue>;
        }
    },
);

export const getVenuesWithProductsWithCategories = createSelector(
    [
        clientVenuesSelector,
        clientProductsSelector,
        clientCategoriesSelector,
    ],
    (venues, products, categories) => mergeStateReducers<{
        venues: SimpleVenue[],
        products: SimpleProduct[],
        categories: SimpleCategory[]
    }>(
        [ venues, products, categories ],
        (venues, products, categories) => ({
            venues,
            products,
            categories,
        }),
    )
)

export const getAdminVenueAvailabilities = createSelector(
    [
        venueAdminAvailabilitiesSelector,
        (_, venueId: number, date: string) => getAvailabilityId(venueId, date),
    ],
    (venueAvailabilities, formattedId): StateReducer<VenueAvailabilities[]> => {
        const availabilities = venueAvailabilities.find(venueAvailability => venueAvailability.id === formattedId);
        if (isNotNull(availabilities)) {
            return availabilities.reducer;
        } else {
            return initialStateReducer as StateReducer<VenueAvailabilities[]>;
        }
    }
)

export const getVenueWithReducers = createSelector(
    [
        (state, venueId) => getVenueById(state, venueId),
        adminProductsSelector,
        discountCodesSelector,
        adminRoomsSelector,
        adminVenuesSelector,
        vouchersSelector,
    ],
    (venue, products, discountCodes, rooms, venues, vouchers) => mergeStateReducers<{
        venue: DetailedVenue,
        products: Product[]
        discountCodes: DiscountCode[]
        rooms: Room[]
        venues: Venue[]
        vouchers: SimpleVoucher[]
    }>(
        [ venue, products, discountCodes, rooms, venues, vouchers ],
        (venue, products, discountCodes, rooms, venues, vouchers) => (({
            venue,
            products,
            discountCodes,
            rooms,
            venues,
            vouchers,
        }))
    )
)
