import { combineEpics, Epic } from "redux-observable";
import { RootAction } from "src/app/store/root.actions";
import { RootState } from "src/app/store/root.reducer";
import { Services } from "src/app/store/services";

import * as apiEpics from "src/app/store/features/api/api.epics";
import * as messageEpics from "src/app/store/features/message/message.epics";
import * as miscEpics from "src/app/store/features/misc/misc.epics";
import * as userEpics from "src/app/store/features/user/user.epics";
import * as wsEpics from "src/app/store/features/ws/ws.epics";
import * as testEpics from "src/app/store/features/pagination/pagination.epics";
import * as venueEpics from "src/app/store/features/venue/venue.epics";
import * as organizationEpics from "src/app/store/features/organization/organization.epics";
import * as roomEpics from "src/app/store/features/room/room.epics";
import * as productEpics from "src/app/store/features/product/product.epics";
import * as discountCodeEpics from "src/app/store/features/discountCode/discountCode.epics";
import * as reservationEpics from "src/app/store/features/reservation/reservation.epics";
import * as categoryEpics from "src/app/store/features/category/category.epics";
import * as voucherEpics from "src/app/store/features/voucher/voucher.epics";
// Epics imports - don't remove
import * as uiLayoutEpics from "src/app/store/features/ui/layout/ui.layout.epics";
import * as uiUserEpics from "src/app/store/features/ui/user/ui.user.epics";
import * as uiVenueEpics from "src/app/store/features/ui/venue/ui.venue.epics";
import * as uiOrganizationEpics from "src/app/store/features/ui/organization/ui.organization.epics";
import * as uiRoomEpics from "src/app/store/features/ui/room/ui.room.epics";
import * as uiProductEpics from "src/app/store/features/ui/product/ui.product.epics";
import * as uiDiscountCodeEpics from "src/app/store/features/ui/discountCode/ui.discountCode.epics";
import * as uiReservationEpics from "src/app/store/features/ui/reservation/ui.reservation.epics";
import * as uiCategoryEpics from "src/app/store/features/ui/category/ui.category.epics";
import * as uiVoucherEpics from "src/app/store/features/ui/voucher/ui.voucher.epics";
// Ui epics imports - don't remove

export type RootEpic = Epic<RootAction, RootAction, RootState, Services>;

export default combineEpics(
	...Object.values(apiEpics),
	...Object.values(messageEpics),
	...Object.values(miscEpics),
	...Object.values(userEpics),
	...Object.values(wsEpics),
	...Object.values(testEpics),
	...Object.values(venueEpics),
	...Object.values(organizationEpics),
	...Object.values(roomEpics),
	...Object.values(productEpics),
	...Object.values(discountCodeEpics),
	...Object.values(reservationEpics),
	...Object.values(categoryEpics),
	...Object.values(voucherEpics),
	// Epics object - don't remove

	...Object.values(uiLayoutEpics),
	...Object.values(uiUserEpics),
	...Object.values(uiVenueEpics),
	...Object.values(uiOrganizationEpics),
	...Object.values(uiRoomEpics),
	...Object.values(uiProductEpics),
	...Object.values(uiReservationEpics),
	...Object.values(uiDiscountCodeEpics),
	...Object.values(uiCategoryEpics),
	...Object.values(uiVoucherEpics),
	// Ui epics object - don't remove
);
