import { LoggedUser } from "src/app/types/api/user.types";
import { SidebarItem } from "src/app/types/util.types";
import { RiDoorOpenFill, RiShoppingBag3Fill } from "react-icons/ri";
import { HiCalendar, HiShoppingCart, HiUserCircle } from "react-icons/hi";
import { canLoggedUserListUsers } from "src/app/utils/abilities";
import { GoOrganization } from "react-icons/go";
import { FaMapMarkedAlt } from "react-icons/fa";
import { BiSolidDiscount } from "react-icons/bi";
import { MdOutlineCategory } from "react-icons/md";
import { TbGiftCardFilled } from "react-icons/tb";

export const getEnvValue = (value: string, defaultValue = ""): string =>
	isDevelopmentEnvironment ?
		(process.env[ value ] || defaultValue) :
		(window as any).config?.[ value ] || process.env[ value ] || defaultValue;

export const isDevelopmentEnvironment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const appVersion = getEnvValue("REACT_APP_VERSION");
export const baseUrl = getEnvValue("REACT_APP_API_URL");
export const defaultView = getEnvValue("REACT_APP_DEFAULT_VIEW");

export const KAKADU_ORG = "44765d0b-b6ca-4ac1-aef9-c321f8fabfaa";

export const getDefaultView = (organizationId: string) => `/panel/organization/${ organizationId }${ defaultView }`;

// Soketi
export const websocketHost = getEnvValue("REACT_APP_WEBSOCKETS_HOST");
export const websocketPort = +getEnvValue("REACT_APP_WEBSOCKETS_PORT");
export const isWebsocketSecure = getEnvValue("REACT_APP_WEBSOCKETS_SECURE") === "true";

export const DEFAULT_PAGINATION_PAGE_INDEX = 0;
export const DEFAULT_PAGINATION_PAGE_SIZE = 10;

// Sidebar
export const sidebarItems: (loggedUser: LoggedUser, organizationId: string) => SidebarItem[] = (loggedUser, organizationId) => {
	const urlPrefix = `/panel/organization/${ organizationId }`;
	return [
		{
			icon: HiCalendar,
			title: "Kalendarz",
			path: `${ urlPrefix }/`,
		}, {
			icon: HiShoppingCart,
			title: "Zamówienia",
			path: `${ urlPrefix }/orders`,
			//hidden: !canLoggedUserListReservations(loggedUser), //todo
		}, {
			icon: GoOrganization,
			title: "Organizacje",
			path: `${ urlPrefix }/organizations`,
			hidden: true,
		}, {
			icon: FaMapMarkedAlt,
			title: "Lokale",
			path: `${ urlPrefix }/venues`,
		}, {
			icon: RiDoorOpenFill,
			title: "Pokoje",
			path: `${ urlPrefix }/rooms`,
		}, {
			icon: RiShoppingBag3Fill,
			title: "Produkty",
			path: `${ urlPrefix }/products`,
		}, {
			icon: BiSolidDiscount,
			title: "Kody rabatowe",
			path: `${ urlPrefix }/discount-codes`,
		}, {
			icon: TbGiftCardFilled,
			title: "Vouchery",
			path: `${ urlPrefix }/vouchers`,
		}, {
			icon: MdOutlineCategory,
			title: "Kategorie",
			path: `${ urlPrefix }/categories`,
			//hidden: !canLoggedUserListCategories(loggedUser), //todo
		}, {
			icon: HiUserCircle,
			title: "Użytkownicy",
			path: `${ urlPrefix }/users`,
			hidden: !canLoggedUserListUsers(loggedUser),
		},
	];
};

export const MAX_FUTURE_MONTHS = 3;

export const reservationColorsArr = [ "#94a3b872", "#fca5a57c", "#fdba7477", "#bef26442", "#d946ef26", "#67e8f930", "#052e163a", "#a5b4fc3f", "#fde04738", "#f9a8d451" ];

//Reservation
export const RESERVATION_SKELETON_ROOM_NUMBER = 4;
export const RESERVATION_SKELETON_ROOM_AVAILABILITIES_NUMBER = 20;
export const VENUE_PARAM_ID = "venueId";

//Utils
export const breakpoints = {
	"XXS": 420,
	"XS": 525,
	"SM": 640,
	"MD": 768,
	"LG": 1024,
	"XL": 1280,
	"2XL": 1536,
}

export const DAY_START_HOUR: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = 6;
