/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { VoucherStatus } from "src/app/types/api/voucher.types";
import { voucherStatusColorDictionary, voucherStatusDictionary } from "src/app/utils/constants/dictionaries";
import { Badge } from "flowbite-react";
import React from "react";

type Props = {
	status: VoucherStatus
};

function VoucherStatusBadge({ status }: Props) {

	return (
		<div className="w-fit">
			<Badge color={ voucherStatusColorDictionary[ status ] }>{ voucherStatusDictionary[ status ] }</Badge>
		</div>
	);
}

export default (VoucherStatusBadge);
