/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Nullable } from "src/app/types/util.types";
import { SimpleOrganization } from "src/app/types/api/organization.types";
import { Voucher } from "src/app/types/api/voucher.types";

export enum DiscountCodeAbility {
	VIEW = "discountCode.view",
	UPDATE = "discountCode.update",
	DELETE = "discountCode.delete",
}

export enum DiscountCodeType {
	AMOUNT = "AMOUNT",
	PERCENTAGE = "PERCENTAGE"
}

export enum DiscountCodeValidityType {
	MAX_USAGES = "MAX_USAGES",
	EXPIRES_AT = "EXPIRES_AT",
	VOUCHER = "VOUCHER",
}

export type SimpleDiscountCode =
	{
		id: number
		code: string

		isActive: boolean
		organizationId: string
		organization: Nullable<SimpleOrganization>
		usages: Nullable<number>
		voucher: Nullable<Voucher>
		createdAt: string
		meta: {
			abilities: DiscountCodeAbility[]
		}
	}
	& ({
		   type: DiscountCodeType.AMOUNT
		   amount: string
	   } | {
		   type: DiscountCodeType.PERCENTAGE
		   percentage: number
	   })
	& ({
		   validityType: DiscountCodeValidityType.EXPIRES_AT
		   expiresAt: string
	   } | {
		   validityType: DiscountCodeValidityType.MAX_USAGES
		   maxUsages: string
	   } | {
		   validityType: DiscountCodeValidityType.VOUCHER
		   maxUsages: string
		   expiresAt: string
	   })

export type DiscountCode =
	SimpleDiscountCode
	& {}

export type DetailedDiscountCode =
	DiscountCode
	& {}

export type ClientDiscountCode =
	{
		type: DiscountCodeType.AMOUNT
		amount: string
	}
	| {
		type: DiscountCodeType.PERCENTAGE
		percentage: number
	}

export type CreateDiscountCodePayload =
	{
		code?: string
		isActive: boolean
	}
	& ({
		   type: DiscountCodeType.AMOUNT
		   amount: string
	   } | {
		   type: DiscountCodeType.PERCENTAGE
		   percentage: number
	   })
	& ({
		   validityType: DiscountCodeValidityType.EXPIRES_AT
		   expiresAt: string
	   } | {
		   validityType: DiscountCodeValidityType.MAX_USAGES
		   maxUsages: string
	   })

export type UpdateDiscountCodePayload =
	{
		id: number
		isActive?: boolean
	}
	& ({
		   type?: DiscountCodeType.AMOUNT
		   amount?: string
	   } | {
		   type?: DiscountCodeType.PERCENTAGE
		   percentage?: number
	   })
	& ({
		   validityType?: DiscountCodeValidityType.EXPIRES_AT
		   expiresAt?: string
	   } | {
		   validityType?: DiscountCodeValidityType.MAX_USAGES
		   maxUsages?: number
	   })
