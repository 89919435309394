import { DetailedVenue, UpdateVenuePayload, Venue } from "src/app/types/api/venue.types";
import QueryParamTabs from "src/app/components/Utils/QueryParamTabs.component";
import { TbListDetails } from "react-icons/tb";
import { BiSolidCalendar } from "react-icons/bi";
import { Product } from "src/app/types/api/product.types";
import { DiscountCode } from "src/app/types/api/discountCode.types";
import VenueHeader from "src/app/components/Venue/VenueHeader.component";
import VenueProfileOverview from "src/app/components/Venue/VenueProfileOverview.component";
import VenueProfileAvailabilities from "src/app/components/Venue/VenueProfileAvailabilities.component";
import { CalculateReservationPricePayload, CreateReservationPayload, UpdateReservationPayload } from "src/app/types/api/reservation.types";
import { Room } from "src/app/types/api/room.types";
import { SimpleVoucher } from "src/app/types/api/voucher.types";

type Props = {
    venue: DetailedVenue
    products: Product[]
    discountCodes: DiscountCode[]
    vouchers: SimpleVoucher[]
    rooms: Room[]
    venues: Venue[]
    updateVenue: (payload: UpdateVenuePayload) => void
    updateReservation: (payload: UpdateReservationPayload) => void
    createReservation: (payload: CreateReservationPayload) => void
    calculateReservationPrice: (payload: CalculateReservationPricePayload) => void
};

export enum VenueProfileTabs {
    OVERVIEW = "overview",
    AVAILABILITIES = "availabilities",
}

function VenueProfileContainer(props: Props) {

    const {
        venue,
        products,
        discountCodes,
        vouchers,
        rooms,
        venues,
        updateVenue,
        updateReservation,
        createReservation,
        calculateReservationPrice,
    } = props;

    return (
        <div className="flex flex-col gap-4">
            <VenueHeader venue={ venue }/>
            <QueryParamTabs<VenueProfileTabs>
                style="underline"
                items={ {
                    [ VenueProfileTabs.OVERVIEW ]: {
                        name: "Informacje",
                        icon: TbListDetails,
                        component: (
                            <VenueProfileOverview
                                venue={ venue }
                                onUpdate={ updateVenue }
                            />
                        ),
                    },
                    [ VenueProfileTabs.AVAILABILITIES ]: {
                        name: "Dostępności",
                        icon: BiSolidCalendar,
                        component: (
                            <VenueProfileAvailabilities
                                venue={ venue }
                                products={ products }
                                discountCodes={ discountCodes }
                                vouchers={ vouchers }
                                rooms={ rooms }
                                venues={ venues }
                                updateReservation={ updateReservation }
                                createReservation={ createReservation }
                                calculateReservationPrice={ calculateReservationPrice }
                            />
                        )
                    },
                } }
            />
        </div>
    );
}

export default VenueProfileContainer;