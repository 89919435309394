/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export const priceOptions = [
	{ value: '100', label: '100 zł' },
	{ value: '200', label: '200 zł' },
	{ value: '500', label: '500 zł' },
	{ value: 'custom', label: 'własna' },
];

export enum PurchaseVoucherRoute {
	ROOMS = "ROOMS",
	VOUCHER = "VOUCHER",
	CONFIRMATION = "CONFIRMATION",
}