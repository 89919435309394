/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import PurchaseVoucherConfirmationContainer from "src/app/containers/Voucher/PurchaseVoucherConfirmation.container";

function PurchaseVoucherConfirmationView() {
	return (
		<PurchaseVoucherConfirmationContainer />
	);
}

export default (PurchaseVoucherConfirmationView);