/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { UpdateReservationPayload } from "src/app/types/api/reservation.types";
import { SimpleRoom } from "src/app/types/api/room.types";
import { Product } from "src/app/types/api/product.types";
import { DiscountCode } from "src/app/types/api/discountCode.types";
import React, { useState } from "react";
import { Modal } from "flowbite-react";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { fetchReservationByIdAsync } from "src/app/store/features/reservation/reservation.actions";
import { getReservationById } from "src/app/store/features/reservation/reservation.selectors";
import UpdateReservationModalInner from "src/app/components/Room/Reservation/UpdateReservationModal/UpdateReservationModalInner.component";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { uiCalculateAdminRoomReservationPrice, uiDeleteReservation, uiFetchAdminRoomAvailabilities } from "src/app/store/features/ui/reservation/ui.reservation.actions";
import { DateTime } from "luxon";
import { isNull } from "src/app/utils/typeguards";
import { DAY_START_HOUR } from "src/app/utils/constants/constants";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { SimpleVoucher } from "src/app/types/api/voucher.types";

type ComponentProps = {
	date: DateTime<boolean>
	isOpen: boolean
	handleClose: () => void
	room: SimpleRoom
	products: Product[]
	discountCodes: DiscountCode[]
	vouchers: SimpleVoucher[]
	onUpdate: (payload: UpdateReservationPayload) => void
	reservationId: number

	/*
	* Put TRUE here if you don't want requests (GET-ReservationById, GET-AdminRoomAvailabilities) to be sent inside the modal.
	* */
	disableRequest?: boolean
}

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

function UpdateReservationModal(props: Props) {

	const {
		date,
		isOpen,
		handleClose,
		room,
		products,
		discountCodes,
		vouchers,
		onUpdate,
		calculateReservationPrice,
		isCalculatingPrice,
		reservationId,
		disableRequest,
		fetchAdminRoomAvailabilities,
		fetchReservation,
		getReservation,
		isDeleting,
		deleteReservation,
	} = props;

	const [ isDeleteReservationModalOpen, toggleDeleteReservationModalOpen ] = useState(false);

	const _getInitialWorkshiftDate = () => {
		const hourWithoutTimezone = date.minus({ minutes: DateTime.local().offset }).hour;
		const isRealNextDay = hourWithoutTimezone >= 0 && hourWithoutTimezone < DAY_START_HOUR;
		return isRealNextDay ? date.minus({ days: 1 }) : date.minus({ minutes: DateTime.local().offset });
	};

	const [ workShiftDate, setWorkShiftDate ] = useState(_getInitialWorkshiftDate());

	return (
		<>
			<Modal
				show={ isOpen || isDeleting }
				onClose={ handleClose }
				size="4xl"
				root={ document.body }
				className="[&>div]:!max-h-[100vh]"
				key={ (isOpen || isDeleting) ? "open" : "hidden" } // AutoFocus on input work with this
			>
				<CachedThenFreshStrategy
					request={ () => {
						if (disableRequest) return;

						fetchReservation(reservationId);
						fetchAdminRoomAvailabilities({ roomId: room?.id ?? 0, date: workShiftDate.toFormat("yyyy-MM-dd") });
					} }
					state={ getReservation(reservationId) }
				>
					{
						reservation =>
							<UpdateReservationModalInner
								date={ workShiftDate }
								changeDate={ date => {
									fetchAdminRoomAvailabilities({ roomId: room?.id ?? 0, date: date.toFormat("yyyy-MM-dd") });
									setWorkShiftDate(date);
								} }
								reservation={ reservation }
								handleClose={ handleClose }
								room={ room }
								products={ products }
								discountCodes={ discountCodes }
								vouchers={ vouchers }
								onUpdate={ payload => onUpdate({ ...payload, dateToRefresh: _getInitialWorkshiftDate().toISO() ?? undefined }) }
								onCalculatePrice={ calculateReservationPrice }
								isOpen={ isOpen }
								isCalculatingPrice={ isCalculatingPrice }
								toggleDeleteReservationModalOpen={ toggleDeleteReservationModalOpen }
								isDeleting={ isDeleting }
							/>
					}
				</CachedThenFreshStrategy>
			</Modal>
			<ConfirmModal
				title="Usuń rezerwacje"
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				isOpen={ isDeleteReservationModalOpen }
				confirmWord="kasuj"
				handleClose={ () => toggleDeleteReservationModalOpen(false) }
				onConfirm={ () => {
					if (isNull(reservationId)) return;
					handleClose();
					toggleDeleteReservationModalOpen(false);
					deleteReservation(reservationId);
				} }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isCalculatingPrice: didLoadingRecordExist(state, { loadableId: props.room?.id ?? 0, loadableType: LoadableType.CALCULATE_ROOM_RESERVATION_PRICE }),
	isDeleting: didLoadingRecordExist(state, { loadableId: props.reservationId ?? 0, loadableType: LoadableType.CALCULATE_ROOM_RESERVATION_PRICE }),
	getReservation: (reservationId: number) => getReservationById(state, { reservationId }),
});

const mapDispatchToProps = {
	fetchAdminRoomAvailabilities: uiFetchAdminRoomAvailabilities,
	fetchReservation: fetchReservationByIdAsync.request,
	calculateReservationPrice: uiCalculateAdminRoomReservationPrice,
	deleteReservation: uiDeleteReservation,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReservationModal);
