/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { AdminAvailability, AvailabilitiesTimeReduce } from "src/app/types/api/reservation.types";
import { Product } from "src/app/types/api/product.types";
import { DiscountCode } from "src/app/types/api/discountCode.types";
import { FormHookReturnType } from "src/app/types/ui/form.types";
import { CreateRoomReservationForm, getRoomReservationDiscountCodeOptions, getRoomReservationVoucherOptions, handleRoomReservationDiscountCodeChange, onRoomReservationProductMinus, onRoomReservationProductPlus } from "src/app/utils/constants/roomReservation.form";
import Select from "src/app/components/Form/Select.component";
import Input from "src/app/components/Form/Input.component";
import PhoneInput from "src/app/components/Form/PhoneInput.component";
import { Checkbox, Label } from "flowbite-react";
import { PulseLoader } from "react-spinners";
import classNames from "classnames";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import React from "react";
import { Nullable, SelectOption } from "src/app/types/util.types";
import { LocaleFromISO } from "src/app/utils/luxon";
import { DateTime } from "luxon";
import TextArea from "src/app/components/Form/TextArea.component";
import ProductListComponent from "src/app/components/Product/ProductList.component";
import CounterCell from "src/app/components/Utils/CounterCell.component";
import SelectDiscountCodeOrVoucher from "src/app/components/Room/Reservation/SelectDiscountCodeOrVoucher.component";
import { SimpleVoucher } from "src/app/types/api/voucher.types";

type Props = {
	availabilities: AdminAvailability[]
	products: Product[]
	discountCodes: DiscountCode[]
	vouchers: SimpleVoucher[]
	form: FormHookReturnType<CreateRoomReservationForm>
	isCalculatingPrice: boolean
}

function CreateReservationModalInner(props: Props) {

	const {
		availabilities,
		products,
		discountCodes,
		vouchers,
		form: {
			form,
			handleChange,
			handleBlur,
			handleSubmit,
		},
		isCalculatingPrice,
	} = props;

	const getStartDateOptions = (): SelectOption<string>[] => availabilities
		.filter(availability => !availability.isReserved)
		.sort((a, b) => LocaleFromISO(a.startTime).valueOf() - LocaleFromISO(b.startTime).valueOf())
		.reduce<AvailabilitiesTimeReduce>((prev, next) => {
			if (isNull(form.endDate.value)) {
				return {
					...prev,
					availabilities: [ ...prev.availabilities, next ],
				};
			}

			const isFinalArray = Number(LocaleFromISO(next.endTime)) === Number(LocaleFromISO(form.endDate.value));
			if (prev.availabilities.some(availability => Number(LocaleFromISO(availability.endTime)) <= Number(LocaleFromISO(next.startTime)))) {
				return {
					isFinalArray: prev.isFinalArray ? true : isFinalArray,
					availabilities: [ ...prev.availabilities, next ],
				};
			} else if (!prev.isFinalArray) {
				return {
					isFinalArray,
					availabilities: [ next ],
				};
			} else {
				return prev;
			}
		}, {
			isFinalArray: false,
			availabilities: [],
		})
		.availabilities
		.filter(availability => isNotNull(form.endDate.value) ? LocaleFromISO(availability.startTime) < LocaleFromISO(form.endDate.value) : true)
		.filter((startTime, index, arr) => arr.indexOf(startTime) === index)
		.map(availability => ({
			label: LocaleFromISO(availability.startTime).toLocaleString(DateTime.TIME_24_SIMPLE),
			value: availability.startTime,
		}));

	const getEndDateOptions = (): SelectOption<string>[] => availabilities
		.filter(availability => !availability.isReserved)
		.sort((a, b) => LocaleFromISO(a.startTime).valueOf() - LocaleFromISO(b.startTime).valueOf())
		.reduce<AvailabilitiesTimeReduce>((prev, next) => {
			if (isNull(form.startDate.value)) {
				return {
					...prev,
					availabilities: [ ...prev.availabilities, next ],
				};
			}

			const isFinalArray = Number(LocaleFromISO(next.startTime)) === Number(LocaleFromISO(form.startDate.value));
			if (prev.availabilities.some(availability => Number(LocaleFromISO(availability.endTime)) <= Number(LocaleFromISO(next.startTime)))) {
				return {
					isFinalArray: prev.isFinalArray ? true : isFinalArray,
					availabilities: [ ...prev.availabilities, next ],
				};
			} else if (!prev.isFinalArray) {
				return {
					isFinalArray,
					availabilities: [ next ],
				};
			} else {
				return prev;
			}
		}, {
			isFinalArray: false,
			availabilities: [],
		})
		.availabilities
		.filter(availability => isNotNull(form.startDate.value) ? DateTime.fromISO(availability.endTime) > DateTime.fromISO(form.startDate.value) : true)
		.filter((endTime, index, arr) => arr.indexOf(endTime) === index)
		.map(availability => ({
			label: DateTime.fromISO(availability.endTime).toLocaleString(DateTime.TIME_24_SIMPLE),
			value: availability.endTime,
		}));

	const handleSelectOnChange = (option: Nullable<SelectOption<string | null | undefined>>) => {
		handleRoomReservationDiscountCodeChange(props.form, option?.value)
	}

	return (
		<div className="space-y-3">
			<div className="grid grid-cols-2 gap-2">
				<div>
					<Select
						label="Czas rozpoczęcia"
						options={ getStartDateOptions() }
						formItem={ form.startDate }
						onChange={ option => {
							handleChange("startDate", option?.value ?? null);
							handleBlur("startDate");
						} }
						isSearchable={ false }
						isClearable
					/>
				</div>
				<div>
					<Select
						label="Czas zakończenia"
						options={ getEndDateOptions() }
						formItem={ form.endDate }
						onChange={ option => {
							handleChange("endDate", option?.value ?? null);
							handleBlur("endDate");
						} }
						isSearchable={ false }
						isClearable
					/>
				</div>
			</div>
			<Input
				formItem={ form.people }
				label="Ilość osób"
				name="people"
				inputProps={ {
					type: "number",
					onChange: (e) => handleChange("people", e.target.value),
					onBlur: () => handleBlur("people"),
					max: form.room.value?.maxPeople,
				} }
			/>
			<div className="grid grid-cols-2 gap-2">
				<Input
					formItem={ form.name }
					label="Imię"
					name="name"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("name", e.target.value),
						onBlur: () => handleBlur("name"),
					} }
				/>
				<Input
					formItem={ form.surname }
					label="Nazwisko"
					name="surname"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("surname", e.target.value),
						onBlur: () => handleBlur("surname"),
					} }
				/>
			</div>
			<div className="grid grid-cols-2 gap-2">
				<Input
					formItem={ form.email }
					label="Email"
					name="email"
					inputProps={ {
						type: "email",
						onChange: (e) => handleChange("email", e.target.value),
						onBlur: () => handleBlur("email"),
					} }
				/>
				<PhoneInput
					label="Numer telefonu"
					name="phone"
					phoneInputProps={ {
						country: "pl",
						onEnterKeyPress: () => handleSubmit(),
					} }
					formItem={ form.phone }
					onChange={ (phone, formattedPhone) => {
						handleChange("phone", phone);
						handleChange("formattedPhone", formattedPhone);
					} }
					onBlur={ () => handleBlur("phone") }
				/>
			</div>
			<div
				className="flex items-center gap-x-3"
				onClick={ () => handleChange("isMarketingConsent", !form.isMarketingConsent.value) }
			>
				<Checkbox
					name="isMarketingConsent"
					checked={ form.isMarketingConsent.value }
					onChange={ () => undefined }
				/>
				<Label htmlFor="isMarketingConsent">
					Zgoda marketingowa
				</Label>
			</div>
			<div
				className="flex items-center gap-x-3"
				onClick={ () => handleChange("isForCompany", !form.isForCompany.value) }
			>
				<Checkbox
					name="isForCompany"
					checked={ form.isForCompany.value }
					onChange={ () => undefined }
				/>
				<Label htmlFor="isForCompany">
					Dane firmy
				</Label>
			</div>
			{
				form.isForCompany.value &&
                <div className="space-y-3">
                    <div className="grid grid-cols-2 gap-2">
                        <Input
                            formItem={ form.companyName }
                            label="Nazwa firmy"
                            name="companyName"
                            inputProps={ {
								type: "text",
								onChange: (e) => handleChange("companyName", e.target.value),
								onBlur: () => handleBlur("companyName"),
							} }
                        />
                        <Input
                            formItem={ form.companyNip }
                            label="NIP"
                            name="companyNip"
                            inputProps={ {
								type: "text",
								onChange: (e) => handleChange("companyNip", e.target.value),
								onBlur: () => handleBlur("companyNip"),
							} }
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <Input
                            formItem={ form.companyAddress }
                            label="Adres"
                            name="companyAddress"
                            inputProps={ {
								type: "text",
								onChange: (e) => handleChange("companyAddress", e.target.value),
								onBlur: () => handleBlur("companyAddress"),
							} }
                        />
                        <Input
                            formItem={ form.companyPostCode }
                            label="Kod pocztowy"
                            name="companyPostCode"
                            inputProps={ {
								type: "text",
								onChange: (e) => handleChange("companyPostCode", e.target.value),
								onBlur: () => handleBlur("companyPostCode"),
							} }
                        />
                    </div>
                    <Input
                        formItem={ form.companyCity }
                        label="Miasto"
                        name="companyCity"
                        inputProps={ {
							type: "text",
							onChange: (e) => handleChange("companyCity", e.target.value),
							onBlur: () => handleBlur("companyCity"),
						} }
                    />
                </div>
			}
			<ProductListComponent
				products={ products }
				disableClick={ true }
				rowsPerPage={ 5 }
				actionsCell={ product => {
					const formProduct = form.products.value.find(({ id }) => id === product.id);
					if (isNull(formProduct)) return;
					return (
						<CounterCell
							onProductPlus={ productId => onRoomReservationProductPlus(props.form, productId) }
							onProductMinus={ productId => onRoomReservationProductMinus(props.form, productId) }
							formProduct={ formProduct }
						/>
					);
				} }
			/>
			<Input
				formItem={ form.reservingPerson }
				label="Kto rezerwuje"
				name="reservingPerson"
				inputProps={ {
					type: "text",
					onChange: e => handleChange("reservingPerson", e.target.value),
					onBlur: () => handleBlur("reservingPerson"),
				} }
			/>
			<TextArea
				label="Komentarz"
				formItem={ form.comment }
				name="createReservationFormComment"
				inputProps={ {
					onChange: e => handleChange("comment", e.target.value),
					onBlur: () => handleBlur("comment"),
				} }
			/>
			<SelectDiscountCodeOrVoucher
				discountCodesOptions={ getRoomReservationDiscountCodeOptions(discountCodes) }
				vouchersOptions={ getRoomReservationVoucherOptions(vouchers) }
				formItem={ form.discountCode }
				onChange={ handleSelectOnChange }
			/>
			<div className="flex gap-2 items-end mx-auto w-max">
				<div className="text-myPrimary-purple-400 text-2xl font-semibold mr-2">
					Cena:
				</div>
				<div className="flex items-end gap-2 relative w-max">
					{
						isCalculatingPrice &&
                        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex">
                            <PulseLoader color="#1b000b" size={ 10 }/>
                        </div>
					}
					<div className={ classNames(
						"text-myPrimary-purple-400 text-5xl font-[900]",
						{ "opacity-50 line-through": isNotNull(form.discountedPrice.value) },
						{ "opacity-50": isCalculatingPrice },
					) }>
						{ form.price.value || 0 }
					</div>
					{
						isNotNull(form.discountedPrice.value) &&
                        <div className="text-myPrimary-purple-300 text-2xl font-semibold">
							{
								form.isDiscountedPriceUnderflow.value
									?
									"0"
									:
									form.discountedPrice.value
							}
                        </div>
					}
				</div>
				<div className="text-myPrimary-purple-300 text-2xl font-semibold">
					zł
				</div>
			</div>
		</div>
	);
}

export default CreateReservationModalInner;
