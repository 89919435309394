/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Form, FormValidator } from "src/app/types/ui/form.types";
import { createFormField, isFormValid, validateEmail, validateField, validateForm, validateNumberField, validatePhone } from "src/app/utils/forms";
import { Nullable } from "src/app/types/util.types";
import { DateTime } from "luxon";
import { AvailabilityState, CalculatePurchasingProcessPricePayload, DateRoomAvailabilities, FormAvailability, PurchasingProcessFormStep, ReservationDiscountCode, VenueAvailabilities } from "src/app/types/api/reservation.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { FormProduct, SimpleProduct } from "src/app/types/api/product.types";
import { SimpleVenue } from "src/app/types/api/venue.types";
import { ReservingData } from "src/app/utils/hooks/useReservingData";
import { LocaleFromISO } from "src/app/utils/luxon";
import { matchPath } from "react-router-dom";

export type PurchasingProcessReducerForm =
	Required<{
		date: string
		dateRoomAvailabilities: DateRoomAvailabilities[]
		venue: Nullable<SimpleVenue>
		products: FormProduct[]
		step: PurchasingProcessFormStep,
		paymentForm: Form<PurchasingProcessPaymentForm>
		price: string
		discountedPrice: Nullable<string>
		isDiscountedPriceUnderflow: boolean
		discountCode: Nullable<ReservationDiscountCode>
		isFormSubmitted: boolean
		hasConfirmAgeInformationModal: boolean
	}>;

export type PurchasingProcessPaymentForm = {
	email: string
	informAboutPromotions: boolean
	isForCompany: boolean
	name: string
	surname: string
	phone: string
	people: number
	maxPeople: number
	acceptStatue: boolean
	discountCode: string

	//Company fields
	companyName: string
	companyNip: string
	companyAddress: string
	companyPostCode: string
	companyCity: string
}

export const purchasingProcessFormInitialState: Form<PurchasingProcessReducerForm> = {
	date: createFormField(DateTime.now().toFormat("yyyy-LL-dd")),
	venue: createFormField(null),
	dateRoomAvailabilities: createFormField([]),
	products: createFormField([]),
	step: createFormField(PurchasingProcessFormStep.AVAILABILITIES),
	paymentForm: createFormField({
		email: createFormField(""),
		informAboutPromotions: createFormField(false),
		isForCompany: createFormField(false),
		name: createFormField(""),
		surname: createFormField(""),
		phone: createFormField(""),
		people: createFormField(0),
		maxPeople: createFormField(1),
		acceptStatue: createFormField(false),
		discountCode: createFormField("", { optional: true }),

		//Company fields
		companyName: createFormField("", { disabled: true }),
		companyNip: createFormField("", { disabled: true }),
		companyAddress: createFormField("", { disabled: true }),
		companyPostCode: createFormField("", { disabled: true }),
		companyCity: createFormField("", { disabled: true }),
	}),
	price: createFormField("0"),
	discountedPrice: createFormField(null),
	isDiscountedPriceUnderflow: createFormField(false),
	discountCode: createFormField(null),
	isFormSubmitted: createFormField(false),
	hasConfirmAgeInformationModal: createFormField(false),
};

export const purchasingProcessFormValidator: FormValidator<PurchasingProcessReducerForm> = {
	date: () => null,
	venue: () => null,
	dateRoomAvailabilities: () => null,
	products: () => null,
	step: () => null,
	price: () => null,
	discountedPrice: () => null,
	isDiscountedPriceUnderflow: () => null,
	discountCode: () => null,
	isFormSubmitted: () => null,
	paymentForm: (paymentForm) => {
		const newForm = validateForm(paymentForm, paymentFormValidator);
		if (isFormValid(newForm)) return null;
		return "Niektóre pola mają nieprawidłową wartośc lub są niewypełnione";
	},
	hasConfirmAgeInformationModal: () => null,
};

export const paymentFormValidator: FormValidator<PurchasingProcessPaymentForm> = {
	email: (email, optional) => validateEmail(email, optional),
	informAboutPromotions: () => null,
	isForCompany: () => null,
	name: (name, optional) => validateField("imię", name, optional),
	surname: (surname, optional) => validateField("nazwisko", surname, optional),
	phone: (phone, optional) => validatePhone(phone, optional),
	people: (people, optional, form) => {
		if (isNotNull(validateNumberField("", people, optional, 1, form.maxPeople.value))) {
			return "Nieprawidłowa ilość";
		} else if (!Number.isInteger(+people)) {
			return "Nieprawidłowa ilość";
		}

		return null;
	},
	maxPeople: () => null,
	acceptStatue: (acceptStatue, optional) => {
		if (acceptStatue || optional) return null;
		return "Regulamin jest wymagany";
	},
	discountCode: () => null,

	//Company fields
	companyName: (companyName, optional) => validateField("Nazwa firmy", companyName, optional),
	companyNip: (companyNip, optional) => validateField("NIP", companyNip, optional),
	companyAddress: (companyAddress, optional) => validateField("Adres", companyAddress, optional),
	companyPostCode: (companyPostCode, optional) => validateField("Kod pocztowy", companyPostCode, optional),
	companyCity: (companyCity, optional) => validateField("Miasto", companyCity, optional),
};

export const mapFormToCalculatePricePayload = (form: Form<PurchasingProcessReducerForm>, reservingData: Nullable<ReservingData>): Nullable<CalculatePurchasingProcessPricePayload> => {

	const { products } = form;

	if (isNull(reservingData)) return null;

	return {
		startDate: reservingData.startTime,
		endDate: reservingData.endTime,
		products: products.value.map(({ id, count }) => ({ id, quantity: count })),
		discountCode: form.paymentForm.value.discountCode.value ?? "",
		roomId: reservingData.room.id,
		nextStep: PurchasingProcessFormStep.ADDITIONS,
	};
};

export const getCurrentDateRoomAvailabilities = (dateRoomAvailabilities: DateRoomAvailabilities[], date: string) => {
	const timestamp = DateTime.fromISO(date).valueOf();

	return dateRoomAvailabilities.find(dateRoomAvailability => DateTime.fromISO(dateRoomAvailability.date).valueOf() === timestamp)?.roomAvailabilities;
};

export const mapPersistedProductsToProducts = (products: SimpleProduct[], persistedProducts: FormProduct[]): FormProduct[] => products.map(product => {
	const persistedProduct = persistedProducts.find(persistedProduct => persistedProduct.id === product.id);
	if (isNull(persistedProduct)) return { ...product, count: 0 };
	return {
		...product,
		count: persistedProduct.count,
	};
});

type BlockType =
	FormAvailability
	& { roomId: number };

export const mapPersistedAvailabilities = (persistedForm: Form<PurchasingProcessReducerForm>, responseRoomAvailabilities: VenueAvailabilities[], date: string): {
	roomAvailabilities: VenueAvailabilities<FormAvailability>[]
	deleteBlock: boolean
	reFetchDate: Nullable<string>
} => {

	let deleteBlock = false;
	let reFetchDate = null;

	// roomAvailabilities where we only give localId and state only based on isReserved field
	const getBasicRoomAvailability = (roomAvailability: VenueAvailabilities) => ({
		...roomAvailability,
		availabilities: roomAvailability.availabilities.map((availability, localId) => {
			const state = availabilityReservedOpenOrPast(availability);
			return { ...availability, localId, state };
		}),
	});

	// "block" is array of every element from persistedAvailabilities with RESERVING or EXTENDABLE state
	const block: BlockType[] = persistedForm.dateRoomAvailabilities.value.reduce<BlockType[]>((prev, current) => {
		return [ ...prev, ...current.roomAvailabilities.reduce<BlockType[]>((innerPrev, innerCurrent) => {
			return [ ...innerPrev, ...innerCurrent.availabilities.reduce<BlockType[]>((previousValue, currentValue) => {
				if (!(currentValue.state === AvailabilityState.RESERVING || currentValue.state === AvailabilityState.EXTENDABLE)) return previousValue;
				return [ ...previousValue, { ...currentValue, roomId: innerCurrent.roomId } ];
			}, []) ];
		}, []) ];
	}, []);

	if (block?.length === 0) {
		return {
			roomAvailabilities: responseRoomAvailabilities.map(roomAvailability => getBasicRoomAvailability(roomAvailability)),
			deleteBlock,
			reFetchDate,
		};
	}

	const roomAvailabilities = responseRoomAvailabilities.map(roomAvailability => {

		//Variable that says if at least one element from block exists in availabilities from backend which is reserved
		const isReservedIsBlock = block
			.filter(({ state }) => state === AvailabilityState.RESERVING)
			.some(blockAvailabilities =>
				roomAvailability.roomId === blockAvailabilities.roomId &&
				roomAvailability.availabilities.some(availability =>
					availability.startTime === blockAvailabilities.startTime &&
					availability.endTime === blockAvailabilities.endTime &&
					availability.isReserved,
				),
			);

		//Variable that says if at least one element from block(RESERVING or EXTENDABLE) exists in availabilities from backend which is in the past
		const isPastInBlock = block
			.filter(({ state }) => state === AvailabilityState.RESERVING)
			.some(availability => DateTime.now().valueOf() >= DateTime.fromISO(availability.endTime).valueOf());

		// If block date is different then currently fetching date we set reFetchDate to block date. Later (in ui.venue.epics.ts) this variable says if we should reFetch or not reFetch availabilities for block date
		if (
			!isPastInBlock &&
			!LocaleFromISO(block[ 0 ].startTime).hasSame(LocaleFromISO(date), "day")
		) {
			reFetchDate = block[ 0 ].startTime;
		}

		// At least one element from block is either reserved or in the past -> we return basic room availabilities
		if (isReservedIsBlock || isPastInBlock) {
			deleteBlock = true;
			return getBasicRoomAvailability(roomAvailability);
		} else {
			return {
				...roomAvailability,
				availabilities: roomAvailability.availabilities.map((availability, localId) => {
					const blockElement = block.find(blockAvailability =>
						blockAvailability.roomId === roomAvailability.roomId &&
						blockAvailability.startTime === availability.startTime &&
						blockAvailability.endTime === availability.endTime &&
						blockAvailability.price === availability.price,
					);
					return ({
						...availability,
						localId,
						state: blockElement?.state ?? availabilityReservedOpenOrPast(availability),
					});
				}),
			};
		}
	});

	return { roomAvailabilities, deleteBlock, reFetchDate };
};

export const getAvailabilityItToLocalize = (prefix: "mobile" | "desktop", venueId: number | undefined, roomId: number, availabilityLocalId: number) => `${ prefix }-${ isNotNull(venueId) ? venueId : "NO-VENUE-ID" }-${ roomId }-${ availabilityLocalId }`;

export const getVenueIdFromURL = (url: string): Nullable<number> => {
	const matchedPath = matchPath("/:venueId/*", url);
	if (isNull(matchedPath)) return null;
	if (isNull(matchedPath.params.venueId)) return null;
	if (isNaN(+matchedPath.params.venueId)) return null;
	return +matchedPath.params.venueId;
};

export const availabilityReservedOpenOrPast = (availability: { isReserved: FormAvailability["isReserved"], endTime: FormAvailability["endTime"] }): AvailabilityState.RESERVED | AvailabilityState.PAST | AvailabilityState.OPEN => {
	if (DateTime.now().valueOf() >= DateTime.fromISO(availability.endTime).valueOf() || availability.isReserved) return AvailabilityState.RESERVED;
	return AvailabilityState.OPEN;
};
