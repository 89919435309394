import userRoutes from "src/app/utils/routes/private/user.routes";
import PaginationView from "src/app/views/Pagination/Pagination.view";
import venueRoutes from "src/app/utils/routes/private/venue.routes";
import organizationRoutes from "src/app/utils/routes/private/organization.routes";
import roomRoutes from "src/app/utils/routes/private/room.routes";
import productRoutes from "src/app/utils/routes/private/product.routes";
import discountCodeRoutes from "src/app/utils/routes/private/discountCode.routes";
import calendarRoutes from "src/app/utils/routes/private/calendar.routes";
import categoryRoutes from "src/app/utils/routes/private/category.routes";
import orderRoutes from "src/app/utils/routes/private/order.routes";
import voucherRoutes from "src/app/utils/routes/private/voucher.routes";
// Routes import plop - don't remove

const privateRoutes = [
	calendarRoutes,
	orderRoutes,
	userRoutes,
	{
		path: "cases",
		element: <PaginationView/>,
	},
	venueRoutes,
	organizationRoutes,
	roomRoutes,
	productRoutes,
	discountCodeRoutes,
	categoryRoutes,
	voucherRoutes,
	// Routes add route plop - don't remove
];

export default privateRoutes;
