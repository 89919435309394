/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CreateVoucherPayload, SimpleVoucher, UpdateVoucherPayload, VoucherDeliveryMethod, VoucherStatus } from "src/app/types/api/voucher.types";
import Table from "src/app/components/Utils/Table.component";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { ModalConfig, Nullable, SortCompare } from "src/app/types/util.types";
import { Badge, Button, Tooltip } from "flowbite-react";
import { voucherDeliveryMethodDictionary, voucherStatusDictionary } from "src/app/utils/constants/dictionaries";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { LocaleFromISO } from "src/app/utils/luxon";
import DeleteCell from "src/app/components/Utils/DeleteCell.component";
import { canDeleteVoucher, canDownloadVoucher, canDownloadVoucherInvoice, canGenerateVoucherInvoice, canSendVoucher, canSendVoucherInvoice, canViewVoucher } from "src/app/utils/abilities";
import React, { MouseEvent, useContext, useState } from "react";
import { customDataTableSearch } from "src/app/utils/helpers";
import VoucherStatusBadge from "src/app/components/Voucher/VoucherStatusBadge.component";
import CreateVoucherModal from "src/app/components/Voucher/CreateVoucherModal.component";
import GenerateInvoiceCell from "src/app/components/Utils/GenerateInvoiceCell.component";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import SendInvoiceCell from "src/app/components/Utils/SendInvoiceCell.component";
import { baseUrl } from "src/app/utils/constants/constants";
import DownloadInvoiceCell from "src/app/components/Utils/DownloadInvoiceCell.component";
import { UserScopeContext } from "src/app/hoc/providers/UserScope.provider";
import UpdateVoucherModal from "src/app/components/Voucher/UpdateVoucherModal.component";
import { MdDownloadForOffline } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";

type Props = {
	vouchers: SimpleVoucher[]
	onCreate: (payload: CreateVoucherPayload) => void
	onUpdate: (payload: UpdateVoucherPayload) => void
	onDelete: (voucherId: number) => void
	onGenerateInvoice: (voucherId: number) => void
	onSendInvoice: (voucherId: number) => void
	isGeneratingInvoice: boolean
	isDeleting: boolean
	isSendingInvoice: boolean
	onSendVoucher: (voucherId: number) => void
	isSendingVoucher: boolean
};

function VoucherListContainer(props: Props) {

	const {
		vouchers,
		onCreate,
		onUpdate,
		onDelete,
		onGenerateInvoice,
		onSendInvoice,
		isGeneratingInvoice,
		isDeleting,
		isSendingInvoice,
		onSendVoucher,
		isSendingVoucher,
	} = props;

	const [ isCreateModalOpen, toggleCreateModal ] = useState(false);
	const [ editModalConfig, setEditModalConfig ] = useState<ModalConfig<SimpleVoucher>>({
		isOpen: false,
		value: null,
	});
	const [ deleteModalConfig, setdeleteModalConfig ] = useState<ModalConfig<SimpleVoucher>>({
		isOpen: false,
		value: null,
	});

	const [ generateInvoiceModalConfig, setGenerateInvoiceModalConfig ] = useState<ModalConfig<number> & { hasBeenGenerated: boolean }>({ isOpen: false, value: null, hasBeenGenerated: false });
	const [ sendInvoiceModalConfig, setSendInvoiceModalConfig ] = useState<ModalConfig<number> & { isInvoiceSent: boolean }>({ isOpen: false, value: null, isInvoiceSent: false });

	const [ sendVoucherModalConfig, setSendVoucherModalConfig ] = useState<ModalConfig<number> & { isVoucherSent: boolean }>({ isOpen: false, value: null, isVoucherSent: false });

	const userScope = useContext(UserScopeContext);

	const columns: MUIDataTableColumn[] = [
		{
			name: "Kupujący",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (voucher: SimpleVoucher) => {
					return (
						<span>{ `${ voucher.nameBuyer } ${ voucher.surnameBuyer }` }</span>
					);
				},
				sortCompare: order => (a: SortCompare<SimpleVoucher>, b: SortCompare<SimpleVoucher>) => {
					const aFullName = `${ a.data.nameBuyer } ${ a.data.surnameBuyer }`;
					const bFullName = `${ b.data.nameBuyer } ${ b.data.surnameBuyer }`;
					if (order === "asc") {
						return (aFullName).localeCompare(bFullName);
					} else {
						return (bFullName).localeCompare(aFullName);
					}
				},
			},
		},
		{
			name: "Kwota",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (voucher: SimpleVoucher) => {
					return `${ voucher.price } zł`;
				},
				sortCompare: order => (a: SortCompare<SimpleVoucher>, b: SortCompare<SimpleVoucher>) => {
					const amountA = +a.data.price;
					const amountB = +b.data.price;
					if (order === "asc") {
						return amountA - amountB;
					} else {
						return amountB - amountA;
					}
				},
			},
		},
		{
			name: "Status",
			options: {
				customBodyRender: ({ status }: SimpleVoucher) => <VoucherStatusBadge status={ status }/>,
				filter: true,
				filterOptions: {
					names: Object.values(VoucherStatus).map(status => voucherStatusDictionary[ status ]),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const status: VoucherStatus = row[ 0 ].status;
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(voucherStatusDictionary[ status ].toLowerCase());
					},
				},
				sort: true,
				sortCompare: order => (a: SortCompare<SimpleVoucher>, b: SortCompare<SimpleVoucher>) => {
					const aStatus = voucherStatusDictionary[ a.data.status ];
					const bStatus = voucherStatusDictionary[ b.data.status ];
					if (order === "asc") {
						return (bStatus).localeCompare(bStatus);
					} else {
						return (bStatus).localeCompare(aStatus);
					}
				},
			},
		},
		{
			name: "Metoda dostawy",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (voucher: SimpleVoucher) => {
					const deliveryMethod = voucherDeliveryMethodDictionary[ voucher.deliveryMethod ];
					if (voucher.deliveryMethod === VoucherDeliveryMethod.ONLINE) {
						return (
							<div className="w-fit">
								<Badge color="indigo">{ deliveryMethod }</Badge>
							</div>
						);
					} else {
						return (
							<div className="w-fit">
								<Badge color="yellow">{ deliveryMethod }</Badge>
							</div>
						);
					}
				},
				filterOptions: {
					names: Object.values(VoucherDeliveryMethod).map(deliveryMethod => voucherDeliveryMethodDictionary[ deliveryMethod ]),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const voucher: SimpleVoucher = row[ 0 ];
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(voucherDeliveryMethodDictionary[ voucher.deliveryMethod ].toLowerCase());
					},
				},
				sortCompare: order => (a: SortCompare<SimpleVoucher>, b: SortCompare<SimpleVoucher>) => {
					const deliveryMethodA = voucherDeliveryMethodDictionary[ a.data.deliveryMethod ];
					const deliveryMethodB = voucherDeliveryMethodDictionary[ b.data.deliveryMethod ];
					if (order === "asc") {
						return deliveryMethodA.localeCompare(deliveryMethodB);
					} else {
						return deliveryMethodB.localeCompare(deliveryMethodA);
					}
				},
			},
		},
		{
			name: "Czy dostarczony?",
			options: {
				filter: true,
				filterOptions: {
					names: [ "Dostarczony", "Niedostarczony" ],
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const voucher: SimpleVoucher = row[ 0 ];
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(voucher.isDelivered ? "dostarczony" : "niedostarczony");
					},
				},
				sort: true,
				sortCompare: order => (a: SortCompare<SimpleVoucher>, b: SortCompare<SimpleVoucher>) => {
					if (order === "asc") {
						return Number(a.data.isDelivered) - Number(b.data.isDelivered);
					} else {
						return Number(b.data.isDelivered) - Number(a.data.isDelivered);
					}
				},
				customBodyRender: (voucher: SimpleVoucher) =>
					<div className="w-fit">
						<Badge color={ voucher.isDelivered ? "success" : "failure" }>
							{ voucher.isDelivered ? "Dostarczony" : "Niedostarczony" }
						</Badge>
					</div>,
			},
		},
		{
			name: "Data utworzenia",
			options: {
				filter: false,
				sort: false,
				customBodyRender: ({ createdAt }: SimpleVoucher) => LocaleFromISO(createdAt).toFormat("dd/MM/yyyy"),
				sortCompare: order => (a: SortCompare<SimpleVoucher>, b: SortCompare<SimpleVoucher>) => {
					if (order === "asc") {
						return Number(a.data.createdAt) - Number(b.data.createdAt);
					} else {
						return Number(b.data.createdAt) - Number(a.data.createdAt);
					}
				},
			},
		},
		{
			name: "Faktura",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (voucher: SimpleVoucher) =>
					<div className="flex flex-row justify-end gap-1">
						<DownloadInvoiceCell
							ability={ canDownloadVoucherInvoice(voucher) }
							onDownload={ e => {
								e.stopPropagation();
							} }
							link={ `${ baseUrl }/scope/${ userScope.organization.id }/admin/vouchers/${ voucher.id }/download-invoice` }
						/>
						<SendInvoiceCell
							ability={ canSendVoucherInvoice(voucher) }
							onSend={ e => {
								e.stopPropagation();
								setSendInvoiceModalConfig({
									isOpen: true,
									value: voucher.id,
									isInvoiceSent: voucher.isForCompany && voucher.isInvoiceSent,
								});
							} }
						/>
						<GenerateInvoiceCell
							ability={ canGenerateVoucherInvoice(voucher) }
							onSend={ e => {
								e.stopPropagation();
								setGenerateInvoiceModalConfig({
									isOpen: true,
									value: voucher.id,
									hasBeenGenerated: isNotNull(voucher.invoice),
								});
							} }
						/>
					</div>,
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
		{
			name: "Akcje",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (voucher: SimpleVoucher) =>
					<div className="flex flex-row justify-end gap-1">
						{
							canDownloadVoucher(voucher) &&
                            <div className="flex gap-2 items-center sm:justify-end">
                                <Tooltip content="Pobierz voucher">
                                    <Button
                                        onClick={ (e: MouseEvent<HTMLButtonElement>) => {
											e.stopPropagation();

										} }
                                        color="gray-outline"
                                        size="sm"
                                        className="p-0 [&>span]:p-2"
                                        href={ `${ baseUrl }/scope/${ userScope.organization.id }/admin/vouchers/${ voucher.id }/download-voucher` }
                                        target="_blank"
                                    >
                                        <MdDownloadForOffline className="w-5 h-5"/>
                                    </Button>
                                </Tooltip>
                            </div>
						}
						{
							canSendVoucher(voucher) &&
                            <div className="flex gap-2 items-center sm:justify-end">
                                <Tooltip content="Wyślij voucher">
                                    <Button
                                        onClick={ (e: MouseEvent<HTMLButtonElement>) => {
											e.stopPropagation();
											setSendVoucherModalConfig({
												isOpen: true,
												value: voucher.id,
												isVoucherSent: voucher.isDelivered,
											});
										} }
                                        color="gray-outline"
                                        size="sm"
                                        className="p-0 [&>span]:p-2"
                                    >
                                        <BiMailSend className="w-5 h-5"/>
                                    </Button>
                                </Tooltip>
                            </div>
						}
						<DeleteCell
							ability={ canDeleteVoucher(voucher) }
							onDelete={ e => {
								e.stopPropagation();
								setdeleteModalConfig({
									isOpen: true,
									value: voucher,
								});
							} }
						/>
					</div>,
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		onRowClick: (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
			const clickedVoucher: Nullable<SimpleVoucher> = vouchers[ rowMeta.dataIndex ];
			if (isNotNull(clickedVoucher) && canViewVoucher(clickedVoucher)) {
				setEditModalConfig({
					isOpen: true,
					value: clickedVoucher,
				});
			}
		},
		customToolbar: () =>
			<Button
				className="order-first mr-3"
				size="sm"
				color="primary"
				onClick={ () => toggleCreateModal(true) }
			>
				Dodaj voucher
			</Button>,
		customSearch: (searchQuery: string, currentRow: SimpleVoucher[]) => customDataTableSearch(searchQuery, [
			`${ currentRow[ 0 ].nameBuyer } ${ currentRow[ 0 ].surnameBuyer }`,
			voucherDeliveryMethodDictionary[ currentRow[ 0 ].deliveryMethod ],
			`${ currentRow[ 0 ].price } zł`,
		]),
	};

	const handleConfirmDelete = () => {
		if (isNotNull(deleteModalConfig.value)) {
			onDelete(deleteModalConfig.value.id);
			setdeleteModalConfig({ isOpen: false, value: null });
		}
	};

	const handleConfirmGenerateInvoice = () => {
		if (isNotNull(generateInvoiceModalConfig.value)) {
			onGenerateInvoice(generateInvoiceModalConfig.value);
			setGenerateInvoiceModalConfig(prev => ({ isOpen: false, value: null, hasBeenGenerated: prev.hasBeenGenerated }));
		}
	};

	const handleConfirmSendInvoice = () => {
		if (isNotNull(sendInvoiceModalConfig.value)) {
			onSendInvoice(sendInvoiceModalConfig.value);
			setSendInvoiceModalConfig(prev => ({ isOpen: false, value: null, isInvoiceSent: prev.isInvoiceSent }));
		}
	};

	const handleConfirmSendVoucher = () => {
		if (isNotNull(sendVoucherModalConfig.value)) {
			onSendVoucher(sendVoucherModalConfig.value);
			setSendVoucherModalConfig(prev => ({ isOpen: false, value: null, isVoucherSent: prev.isVoucherSent }));
		}
	};

	return (
		<>
			<Table
				title="Vouchery"
				columns={ columns }
				options={ tableOptions }
				data={ vouchers }
			/>
			<CreateVoucherModal
				isOpen={ isCreateModalOpen }
				handleClose={ () => toggleCreateModal(false) }
				onCreate={ onCreate }
			/>
			<ConfirmModal
				title="Usuń voucher"
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				confirmWord="kasuj"
				isLoading={ isDeleting }
				isOpen={ deleteModalConfig.isOpen }
				handleClose={ () => setdeleteModalConfig({ isOpen: false, value: null }) }
				onConfirm={ handleConfirmDelete }
			/>
			<ConfirmModal
				title="Wyślij fakturę"
				warning={ <div>Czy jesteś pewień? Ta operacja jest nieodwracalna. { sendInvoiceModalConfig.isInvoiceSent ? <div>Faktura została już wysłana.</div> : "" }</div> }
				confirmWord="wyślij"
				isLoading={ isSendingInvoice }
				isOpen={ sendInvoiceModalConfig.isOpen }
				handleClose={ () => setSendInvoiceModalConfig({ isOpen: false, value: null, isInvoiceSent: false }) }
				onConfirm={ handleConfirmSendInvoice }
			/>
			<ConfirmModal
				title="Wygeneruj fakturę"
				warning={ <div>Czy jesteś pewień? Ta operacja jest nieodwracalna. { generateInvoiceModalConfig.hasBeenGenerated ? <div>Faktura została już wygenerowana.</div> : "" }</div> }
				confirmWord="wygeneruj"
				isLoading={ isGeneratingInvoice }
				isOpen={ generateInvoiceModalConfig.isOpen }
				handleClose={ () => setGenerateInvoiceModalConfig({ isOpen: false, value: null, hasBeenGenerated: false }) }
				onConfirm={ handleConfirmGenerateInvoice }
			/>
			<ConfirmModal
				title="Wyślij voucher"
				warning={ <div>Czy jesteś pewień? Ta operacja jest nieodwracalna. { sendVoucherModalConfig.isVoucherSent ? <div>Voucher został już wysłany.</div> : "" }</div> }
				confirmWord="wyślij"
				isLoading={ isSendingVoucher }
				isOpen={ sendVoucherModalConfig.isOpen }
				handleClose={ () => setSendVoucherModalConfig({ isOpen: false, value: null, isVoucherSent: false }) }
				onConfirm={ handleConfirmSendVoucher }
			/>
			{
				isNotNull(editModalConfig.value) &&
                <UpdateVoucherModal
                    isOpen={ editModalConfig.isOpen }
                    handleClose={ () => setEditModalConfig({ isOpen: false }) }
                    voucher={ editModalConfig.value }
                    onUpdate={ onUpdate }
                />
			}
		</>
	);
}

export default (VoucherListContainer);