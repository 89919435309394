/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Organization, SimpleOrganization } from "src/app/types/api/organization.types";
import { Nullable } from "src/app/types/util.types";
import { ClientDiscountCode, DiscountCode } from "src/app/types/api/discountCode.types";
import { Payment, SimplePayment } from "src/app/types/api/payment.types";

export enum VoucherAbility {
	VIEW = "voucher.view",
	UPDATE = "voucher.update",
	DELETE = "voucher.delete",
	GENERATE_INVOICE = "voucher.generateInvoice",
	SEND_INVOICE = "voucher.sendInvoiceEmail",
	DOWNLOAD_INVOICE = "voucher.downloadInvoice",
	SEND_VOUCHER = "voucher.sendVoucherEmail",
	DOWNLOAD_VOUCHER = "voucher.downloadVoucher"
}

export enum VoucherStatus {
	NEW = "NEW",
	PAID = "PAID",
	USED = "USED",
}

export enum VoucherDeliveryMethod {
	ONLINE = "ONLINE",
	COURIER = "COURIER",
}

export type SimpleVoucher =
	{
		id: number

		organizationId: string
		organization: Nullable<SimpleOrganization>

		nameReceiver: string
		surnameReceiver: string
		nameBuyer: string
		surnameBuyer: string
		deliveryMethod: VoucherDeliveryMethod
		emailBuyer: string
		phoneBuyer: string

		city: Nullable<string>
		street: Nullable<string>
		postalCode: Nullable<string>

		isDelivered: boolean
		status: VoucherStatus
		price: number
		discountCodeId: number
		discountCodeCode: string

		isInvoiceSent: boolean
		invoice: Nullable<string>

		expiresAt: string
		isActive: boolean

		createdAt: string
		updatedAt: string

		meta: {
			abilities: VoucherAbility[]
		}
	}
	& ({
		   isForCompany: true
		   companyName: string
		   companyNip: string
		   companyAddress: string
		   companyPostCode: string
		   companyCity: string
	   } | {
		   isForCompany: false
	   })

export type Voucher =
	SimpleVoucher
	& {
		discountCode: Nullable<ClientDiscountCode>
		payment: SimplePayment
	}

export type DetailedVoucher =
	Voucher
	& {
		discountCode: DiscountCode
		payment: Payment
		organization: Organization
	}

export type CreateVoucherPayload =
	{
		nameReceiver: string
		surnameReceiver: string

		nameBuyer: string
		surnameBuyer: string
		emailBuyer: string
		phoneBuyer: string

		price: number
		expiresAt?: string
		isActive?: boolean
	}
	& ({
		   isForCompany: false
	   } | {
		   isForCompany: true
		   companyName: string
		   companyNip: string
		   companyAddress: string
		   companyPostCode: string
		   companyCity: string
	   })
	& ({
		   deliveryMethod: VoucherDeliveryMethod.ONLINE
	   } | {
		   deliveryMethod: VoucherDeliveryMethod.COURIER
		   city: string
		   street: string
		   postalCode: string
	   })

export type UpdateVoucherPayload =
	{
		id: number

		nameReceiver?: string
		surnameReceiver?: string

		nameBuyer?: string
		surnameBuyer?: string
		emailBuyer?: string
		phoneBuyer?: string

		price?: number
		expiresAt?: string
		isActive?: boolean
		isDelivered?: boolean
		status?: VoucherStatus
	}
	& ({
		   isForCompany?: false
	   } | {
		   isForCompany?: true
		   companyName?: string
		   companyNip?: string
		   companyAddress?: string
		   companyPostCode?: string
		   companyCity?: string
	   })
	& ({
		   deliveryMethod?: VoucherDeliveryMethod.ONLINE
	   } | {
		   deliveryMethod?: VoucherDeliveryMethod.COURIER
		   city?: string
		   street?: string
		   postalCode?: string
	   })