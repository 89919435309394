/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createAction } from "typesafe-actions";
import { CreateVoucherPayload, UpdateVoucherPayload } from "src/app/types/api/voucher.types";

export const uiPurchaseVoucher = createAction("UI_VOUCHER__PURCHASE_VOUCHER")<CreateVoucherPayload>();
export const uiCreateVoucher = createAction("UI_VOUCHER__CREATE_VOUCHER")<CreateVoucherPayload>();
export const uiUpdateVoucher = createAction("UI_VOUCHER__UPDATE_VOUCHER")<UpdateVoucherPayload>();
export const uiDeleteVoucher = createAction("UI_VOUCHER__DELETE_VOUCHER")<number>();

export const uiGenerateVoucherInvoice = createAction("UI_VOUCHER__GENERATE_VOUCHER_INVOICE")<number>();
export const uiSendVoucherInvoice = createAction("UI_VOUCHER__SEND_VOUCHER_INVOICE")<number>();

export const uiGenerateVoucher = createAction("UI_VOUCHER__GENERATE_VOUCHER")<number>();
export const uiSendVoucher = createAction("UI_VOUCHER__SEND_VOUCHER")<number>();
