/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import LogoImage from "src/app/components/Utils/Logo/LogoImage.component";
import { FormHookReturnType } from "src/app/types/ui/form.types";
import { PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { HiLocationMarker } from "react-icons/hi";
import { getAddressString, getAvailabilityId, getGoogleMapsLink } from "src/app/utils/helpers";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { Nullable } from "src/app/types/util.types";
import classNames from "classnames";
import { SimpleVenue, Venue } from "src/app/types/api/venue.types";
import ReservationVenueSelect from "src/app/components/PurchasingProcess/util/Header/PurchasingProcessVenueSelect.component";
import LogoShortImage from "src/app/components/Utils/Logo/LogoShort.component";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { breakpoints } from "src/app/utils/constants/constants";
import { Link, useNavigate } from "react-router-dom";
import { purchasingProcessFormStepRouteDictionary } from "src/app/utils/constants/dictionaries";
import { PurchasingProcessFormStep } from "src/app/types/api/reservation.types";
import { useEffect, useRef } from "react";
import { addLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { Button } from "flowbite-react";

type ComponentProps = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	height: number
	fetchAvailabilities: (venueId: number, date: string) => void
	venues: SimpleVenue[]
	setActualHeaderHeight: (height: number) => void
	actualHeaderHeight: number
	windowScrollValue: number
	chooseDateHeight: number
}

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

function ReservationSelectVenueHeader(props: Props) {

	const {
		form: {
			form: {
				date,
				venue,
			},
			handleChange,
		},
		height,
		fetchAvailabilities,
		venues,
		setActualHeaderHeight,
		actualHeaderHeight,
		windowScrollValue,
		chooseDateHeight,
		bodyWidth,
		addLoadingRecord,
	} = props;

	const headerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isNull(headerRef.current)) return;
		setActualHeaderHeight(parseInt(window.getComputedStyle(headerRef.current).height));
	}, [ bodyWidth ]);

	const navigate = useNavigate();

	const address = {
		street: venue.value?.street,
		houseNumber: venue.value?.houseNumber,
		flatNumber: venue.value?.flatNumber,
		postCode: venue.value?.postCode,
		city: venue.value?.city,
		country: venue.value?.country,
	};

	const onSelectChange = (venue: Nullable<Venue>) => {
		handleChange("venue", venue);
		handleChange("dateRoomAvailabilities", []);
		if (isNull(venue)) return;
		const loadingRecord: LoadingRecord = { loadableId: getAvailabilityId(venue?.id ?? 0, date.value), loadableType: LoadableType.FETCH_AVAILABILITIES };
		addLoadingRecord(loadingRecord);
		fetchAvailabilities(venue.id, date.value);
		navigate(purchasingProcessFormStepRouteDictionary(venue.id)[ PurchasingProcessFormStep.AVAILABILITIES ]);
	};

	return (
		<div
			className={ classNames(
				"flex justify-between items-center bg-myPrimary-purple-500 px-6 z-30",
				"flex-col-reverse gap-6 py-5",
				"md:flex-row md:gap-4 md:py-0",
			) }
			style={ {
				height: bodyWidth > breakpoints.SM ? height : "auto",
				marginBottom: windowScrollValue >= actualHeaderHeight ? chooseDateHeight : 0,
			} }
			ref={ headerRef }
			id="purchase-header"
		>
			<div className={ classNames(
				"flex gap-3",
				"flex-col",
				"xxs:flex-row",
				"md:flex-col",
			) }>
				<ReservationVenueSelect
					venues={ venues }
					onChange={ onSelectChange }
					formItem={ venue }
				/>
				<div className={ classNames(
					"text-myPrimary-orange-500 text-sm flex items-center gap-2",
					{ "opacity-0": isNull(venue.value) },
				) }>
					<HiLocationMarker className="text-3xl"/>
					<Link
						className="italic hover:underline"
						target="_blank"
						to={ isNotNull(address.city) ? getGoogleMapsLink({ ...address, city: address.city }) : "" }
					>
						{ getAddressString(address) }
					</Link>
				</div>
				<Link to="/voucher" className="w-fit hidden md:block">
					<Button
						color="orange-full"
						onClick={ () => undefined }
					>
						Kup voucher
					</Button>
				</Link>
			</div>
			<div className="flex flex-row gap-2 w-full items-center justify-between md:hidden">
				<LogoShortImage className={ classNames("block h-6 sm:h-8") }/>
				<Link to="/voucher" className="ml-auto">
					<Button
						color="orange-full"
						onClick={ () => undefined }
						size="xs"
					>
						Kup voucher
					</Button>
				</Link>
			</div>
			<LogoImage className={ classNames("hidden md:block sm:h-28 md:h-36") }/>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodyWidth: state.ui.layout.bodySize.width,
});

const mapDispatchToProps = {
	addLoadingRecord: addLoadingRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationSelectVenueHeader);
