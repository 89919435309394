import { CopyTimeRangesFromOtherRoomPayload, DetailedRoom, Room, UpdateRoomPayload, UpdateRoomTimeRangesPayload } from "src/app/types/api/room.types";
import QueryParamTabs from "src/app/components/Utils/QueryParamTabs.component";
import { TbListDetails } from "react-icons/tb";
import { HiClock } from "react-icons/hi";
import RoomHeader from "src/app/components/Room/RoomHeader.component";
import RoomInformation from "src/app/components/Room/RoomInformation.component";
import { Venue } from "src/app/types/api/venue.types";
import RoomTimeWindows from "src/app/components/Room/RoomTimeWindows.component";
import { BiSolidCalendar } from "react-icons/bi";
import RoomAvailabilities from "src/app/components/Room/RoomAvailabilities.component";
import { DiscountCode } from "src/app/types/api/discountCode.types";
import { Product } from "src/app/types/api/product.types";
import { SimpleVoucher } from "src/app/types/api/voucher.types";

type Props = {
	room: DetailedRoom
	venues: Venue[]
	rooms: Room[]
	discountCodes: DiscountCode[]
	vouchers: SimpleVoucher[]
	products: Product[]
	onUpdateBasicInformation: (payload: UpdateRoomPayload) => void
	onCreateTimeWindow: (payload: UpdateRoomTimeRangesPayload) => void
	onEditTimeWindow: (payload: UpdateRoomTimeRangesPayload) => void
	onCopyTimeWindow: (payload: UpdateRoomTimeRangesPayload) => void
	onCopyTimeWindowsFromOtherRoom: (payload: CopyTimeRangesFromOtherRoomPayload) => void
	onDeleteTimeWindow: (payload: UpdateRoomTimeRangesPayload) => void
};

export enum RoomProfileTabs {
	OVERVIEW = "overview",
	TIME_WINDOW = "timeWindow",
	AVAILABILITIES = "availabilities",
}

function RoomProfileContainer(props: Props) {

	const {
		room,
		venues,
		rooms,
		products,
		discountCodes,
		vouchers,
		onUpdateBasicInformation,
		onCreateTimeWindow,
		onEditTimeWindow,
		onCopyTimeWindow,
		onCopyTimeWindowsFromOtherRoom,
		onDeleteTimeWindow,
	} = props;

	return (
		<div className="flex flex-col gap-4">
			<RoomHeader
				room={ room }
			/>
			<QueryParamTabs
				style="underline"
				items={ {
					[ RoomProfileTabs.OVERVIEW ]: {
						name: "Informacje",
						icon: TbListDetails,
						component: (
							<RoomInformation
								room={ room }
								venues={ venues }
								onUpdate={ onUpdateBasicInformation }
							/>
						),
					},
					[ RoomProfileTabs.TIME_WINDOW ]: {
						name: "Okna czasowe",
						icon: HiClock,
						component: (
							<RoomTimeWindows
								room={ room }
								rooms={ rooms }
								onCreateTimeWindow={ onCreateTimeWindow }
								onEditTimeWindow={ onEditTimeWindow }
								onCopyTimeWindow={ onCopyTimeWindow }
								onCopyTimeWindowsFromOtherRoom={ onCopyTimeWindowsFromOtherRoom }
								onDeleteTimeWindow={ onDeleteTimeWindow }
							/>
						),
					},
					[ RoomProfileTabs.AVAILABILITIES ]: {
						name: "Dostępności",
						icon: BiSolidCalendar,
						component: (
							<RoomAvailabilities
								room={ room }
								products={ products }
								discountCodes={ discountCodes }
								rooms={ rooms }
								venues={ venues }
								vouchers={ vouchers }
							/>
						),
					},
				} }
			/>
		</div>
	);
}

export default (RoomProfileContainer);
