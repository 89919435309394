import { combineReducers, Reducer } from "redux";
import { StateType } from "typesafe-actions";
import { RouterState } from "redux-first-history";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import messageReducer from "src/app/store/features/message/message.reducer";
import formReducer from "src/app/store/features/form/form.reducer";
import userReducer from "src/app/store/features/user/user.reducer";
import miscReducer from "src/app/store/features/misc/misc.reducer";
import uiReducer from "src/app/store/features/ui/ui.reducer";
import wsReducer from "src/app/store/features/ws/ws.reducer";
import paginationReducer from "src/app/store/features/pagination/pagination.reducer";
import venueReducer from "src/app/store/features/venue/venue.reducer";
import organizationReducer from "src/app/store/features/organization/organization.reducer";
import roomReducer from "src/app/store/features/room/room.reducer";
import productReducer from "src/app/store/features/product/product.reducer";
import discountCodeReducer from "src/app/store/features/discountCode/discountCode.reducer";
import reservationReducer from "src/app/store/features/reservation/reservation.reducer";
import categoryReducer from "src/app/store/features/category/category.reducer";
import voucherReducer from "src/app/store/features/voucher/voucher.reducer";
// Reducers imports - don't remove

export type RootState = StateType<ReturnType<typeof rootReducer>>;

const userPersistConfig = {
	key: "user",
	storage: storage,
	whitelist: [ "loggedUser", "userScope" ],
};

const miscPersistConfig = {
	key: "misc",
	storage: storage,
	whitelist: [ "impersonateUserId" ],
};

const formPersistConfig = {
	key: "form",
	storage: storage,
	whitelist: [ "purchasingProcess" ],
}

const rootReducer = (routerReducer: Reducer<RouterState>) =>
	combineReducers({
		router: routerReducer,
		message: messageReducer,
		form: persistReducer(formPersistConfig, formReducer),
		misc: persistReducer(miscPersistConfig, miscReducer),
		ui: uiReducer,
		ws: wsReducer,
		user: persistReducer(userPersistConfig, userReducer),
		pagination: paginationReducer,
		venue: venueReducer,
		organization: organizationReducer,
		room: roomReducer,
		product: productReducer,
		discountCode: discountCodeReducer,
		reservation: reservationReducer,
		category: categoryReducer,
		voucher: voucherReducer,
		// Reducers object - don't remove
	});

export default rootReducer;
