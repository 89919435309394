/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DetailedRoom, Room } from "src/app/types/api/room.types";
import { Product } from "src/app/types/api/product.types";
import { DiscountCode } from "src/app/types/api/discountCode.types";
import React, { useState } from "react";
import { DateTime } from "luxon";
import { RootState } from "src/app/store/root.reducer";
import { getAdminRoomAvailabilities } from "src/app/store/features/reservation/reservation.selectors";
import { connect } from "react-redux";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { uiCreateAdminReservation, uiFetchAdminRoomAvailabilities, uiFetchReservationById, uiUpdateReservation } from "src/app/store/features/ui/reservation/ui.reservation.actions";
import { Button } from "flowbite-react";
import CreateReservationModal from "src/app/components/Room/Reservation/CreateReservationModal/CreateReservationModal.component";
import { ModalConfig, Nullable } from "src/app/types/util.types";
import { AdminAvailability, Reservation } from "src/app/types/api/reservation.types";
import AdminSingleAvailability from "src/app/components/Room/AdminSingleAvailability.component";
import UpdateReservationModal from "src/app/components/Room/Reservation/UpdateReservationModal/UpdateReservationModal.component";
import { LocaleFromISO } from "src/app/utils/luxon";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { GridLoader } from "react-spinners";
import DatePickerHeader from "src/app/components/Utils/DatePickerHeader.component";
import { reservationColorsArr } from "src/app/utils/constants/constants";
import { Venue } from "src/app/types/api/venue.types";
import { SimpleVoucher } from "src/app/types/api/voucher.types";

type ComponentProps = {
	room: DetailedRoom
	products: Product[]
	discountCodes: DiscountCode[]
	vouchers: SimpleVoucher[]
	rooms: Room[]
	venues: Venue[]
};

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

function RoomAvailabilities(props: Props) {

	const {
		room,
		products,
		discountCodes,
		vouchers,
		rooms,
		venues,
		createReservation,
		updateReservation,
		isUpdating,
		fetchAdminRoomAvailabilities,
		getAdminRoomAvailabilities,
		fetchReservation,
		isFetchingReservation,
		isFetchingRoomAvailabilities,
	} = props;

	const [ createReservationModalConfig, setCreateReservationModalConfig ] = useState<ModalConfig<AdminAvailability>>({ isOpen: false });
	const [ updateReservationModalConfig, setUpdateReservationModalConfig ] = useState<ModalConfig<{ reservationId: number, startDate: string }>>({ isOpen: false });
	const [ date, setDate ] = useState<DateTime>(LocaleFromISO(DateTime.now().toISO()));

	const getReservationColor = (availabilities: AdminAvailability[], availabilityReservations: Reservation[]) => {
		const reservations = availabilities
			.reduce<number[]>(
				(prev, next) =>
					[
						...prev,
						...next.reservations.map(reservation => reservation.id),
					],
				[],
			)
			.filter((id, index, array) => array.indexOf(id) === index)
			.map((reservationId, i) => ({
				reservationId: reservationId,
				color: reservationColorsArr[ i % 10 ],
			}));

		return reservations.find(({ reservationId }) => isNotNull(availabilityReservations.find(reservation => reservation.id === reservationId)))?.color;
	};

	const updateReservationModalRequest = (reservationId: Nullable<number>, startDate: string) => {
		if (isNull(reservationId)) return;
		setUpdateReservationModalConfig({ isOpen: true, value: { reservationId, startDate } });
		fetchReservation(reservationId);
		fetchAdminRoomAvailabilities({ roomId: room.id ?? 0, date: date.toFormat("yyyy-MM-dd") });
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="flex gap-2 items-center justify-between">
				<DatePickerHeader
					onDateChange={ setDate }
					date={ date }
				/>
				<div>
					<Button
						size="sm"
						color="primary"
						onClick={ () => setCreateReservationModalConfig({ isOpen: true }) }
					>
						Dodaj rezerwacje
					</Button>
				</div>
			</div>
			<div className="relative">
				{
					(
						isUpdating ||
						isFetchingRoomAvailabilities ||
						isFetchingReservation(updateReservationModalConfig.value?.reservationId ?? -1)
					) &&
                    <div className="absolute top-0 left-0 w-full h-full backdrop-blur-[2px] flex items-center justify-center z-10">
                        <GridLoader size={ 30 } color="#eb5600"/>
                    </div>
				}
				<CachedThenFreshStrategy
					request={ () => fetchAdminRoomAvailabilities({ roomId: room.id, date: date.toFormat("yyyy-MM-dd") }) }
					state={ getAdminRoomAvailabilities(room.id, date) }
					useEffectDependency={ date.toFormat("yyyy-MM-dd") }
				>
					{
						availabilities =>
							<>
								<div className="flex gap-4 flex-wrap">
									{
										availabilities.length === 0 ?
											<div className="font-[900] text-myPrimary-purple-400 mx-auto text-2xl">
												Brak dostępności
											</div> :
											availabilities
												.sort((a, b) => LocaleFromISO(a.startTime).valueOf() - LocaleFromISO(b.startTime).valueOf())
												.map((availability, index) => {
													// const currentDateReservation = availability.reservations.find(reservation => {
													// 	const reservationStartDate = LocaleFromISO(reservation.startDate);
													// 	return (
													// 		reservationStartDate.year === date.year &&
													// 		reservationStartDate.month === date.month &&
													// 		reservationStartDate.day === date.day
													// 	)
													// });
													const currentDateReservation = availability.reservations[ 0 ];

													return (
														<AdminSingleAvailability
															key={ index }
															availability={ availability }
															onReserveClick={ availability => setCreateReservationModalConfig({ isOpen: true, value: availability }) }
															onEditClick={ reservationId => updateReservationModalRequest(reservationId, availability.startTime) }
															currentDateReservation={ currentDateReservation }
															color={ getReservationColor(availabilities, availability.reservations) }
														/>
													);
												})
									}
								</div>
								<CreateReservationModal
									date={ date }
									isOpen={ createReservationModalConfig.isOpen }
									handleClose={ () => setCreateReservationModalConfig({ isOpen: false }) }
									discountCodes={ discountCodes }
									vouchers={ vouchers }
									products={ products }
									availability={ createReservationModalConfig.value }
									onCreate={ createReservation }
									room={ room }
									venue={ room.venue ?? undefined }
									rooms={ rooms }
									venues={ venues }
								/>
								{
									(
										isNotNull(updateReservationModalConfig.value) &&
										!isFetchingReservation(updateReservationModalConfig.value.reservationId)
									) &&
                                    <UpdateReservationModal
                                        date={ LocaleFromISO(updateReservationModalConfig.value.startDate) }
                                        isOpen={ updateReservationModalConfig.isOpen }
                                        handleClose={ () => setUpdateReservationModalConfig({ isOpen: false }) }
                                        room={ room }
                                        discountCodes={ discountCodes }
                                        vouchers={ vouchers }
                                        products={ products }
                                        reservationId={ updateReservationModalConfig.value.reservationId }
                                        onUpdate={ updateReservation }
                                        disableRequest={ true }
                                    />
								}
							</>
					}
				</CachedThenFreshStrategy>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	getAdminRoomAvailabilities: (roomId: number, date: DateTime) => getAdminRoomAvailabilities(state, { roomId, date }),
	isUpdating: didLoadingRecordExist(state, { loadableType: LoadableType.UPDATE_RESERVATION }),
	isFetchingReservation: (reservationId: number) => didLoadingRecordExist(state, { loadableId: reservationId, loadableType: LoadableType.FETCH_RESERVATION_BY_ID }),
	isFetchingRoomAvailabilities: didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_ADMIN_ROOM_AVAILABILITIES }),
});

const mapDispatchToProps = {
	fetchAdminRoomAvailabilities: uiFetchAdminRoomAvailabilities,
	fetchReservation: uiFetchReservationById,
	createReservation: uiCreateAdminReservation,
	updateReservation: uiUpdateReservation,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomAvailabilities);
