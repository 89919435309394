/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { CreateVoucherPayload, DetailedVoucher, SimpleVoucher, UpdateVoucherPayload, Voucher } from "src/app/types/api/voucher.types";

export const fetchVouchersAsync = createNetworkAction<undefined, SuccessPayload<SimpleVoucher[]>>("VOUCHER__FETCH_VOUCHERS");
export const fetchVoucherByIdAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedVoucher>, FailurePayloadWithId>("VOUCHER__FETCH_VOUCHER_BY_ID");
export const purchaseVoucherAsync = createNetworkAction<CreateVoucherPayload, SuccessPayload<Voucher>>("VOUCHER__PURCHASE_VOUCHER");
export const createVoucherAsync = createNetworkAction<CreateVoucherPayload, SuccessPayload<Voucher>>("VOUCHER__CREATE_VOUCHER");
export const updateVoucherAsync = createNetworkAction<UpdateVoucherPayload, SuccessPayload<DetailedVoucher>>("VOUCHER__UPDATE_VOUCHER");
export const deleteVoucherByIdAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("VOUCHER__DELETE_VOUCHER");

export const generateVoucherInvoiceAsync = createNetworkAction<number, SuccessPayload<DetailedVoucher>>("VOUCHER__GENERATE_VOUCHER_INVOICE");
export const sendVoucherInvoiceAsync = createNetworkAction<number, SuccessPayload<DetailedVoucher>>("VOUCHER__SEND_VOUCHER_INVOICE");

export const generateVoucherAsync = createNetworkAction<number, SuccessPayload<DetailedVoucher>>("VOUCHER__GENERATE_VOUCHER");
export const sendVoucherAsync = createNetworkAction<number, SuccessPayload<DetailedVoucher>>("VOUCHER__SEND_VOUCHER");

export const fetchPaidVouchersAsync = createNetworkAction<undefined, SuccessPayload<SimpleVoucher[]>>("VOUCHER__FETCH_PAID_VOUCHERS");
