/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { uiCreateVoucher, uiDeleteVoucher, uiGenerateVoucher, uiGenerateVoucherInvoice, uiPurchaseVoucher, uiSendVoucher, uiSendVoucherInvoice, uiUpdateVoucher } from "src/app/store/features/ui/voucher/ui.voucher.actions";
import { filter, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { createVoucherAsync, deleteVoucherByIdAsync, generateVoucherAsync, generateVoucherInvoiceAsync, purchaseVoucherAsync, sendVoucherAsync, sendVoucherInvoiceAsync, updateVoucherAsync } from "src/app/store/features/voucher/voucher.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import { isNotNull } from "src/app/utils/typeguards";

export const uiPurchaseVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiPurchaseVoucher)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.PURCHASE_VOUCHER };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(purchaseVoucherAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(purchaseVoucherAsync.success, action) || isActionOf(purchaseVoucherAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(purchaseVoucherAsync.success, responseAction)) {
							isNotNull(responseAction.payload.data.payment) && window.open(responseAction.payload.data.payment?.paymentUrl, "_self");
							return merge(
								of(removeLoadingRecord(loadingRecord)),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiCreateVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateVoucher)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_VOUCHER };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createVoucherAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createVoucherAsync.success, action) || isActionOf(createVoucherAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createVoucherAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Poprawnie utworzono voucher!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateVoucher)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_VOUCHER };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateVoucherAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateVoucherAsync.success, action) || isActionOf(updateVoucherAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateVoucherAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie edytowano voucher!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteVoucher)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.DELETE_VOUCHER };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteVoucherByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteVoucherByIdAsync.success, action) || isActionOf(deleteVoucherByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteVoucherByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto voucher!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiGenerateVoucherInvoiceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiGenerateVoucherInvoice)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.GENERATE_VOUCHER_INVOICE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(generateVoucherInvoiceAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(generateVoucherInvoiceAsync.success, action) || isActionOf(generateVoucherInvoiceAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(generateVoucherInvoiceAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: `Pomyślnie wygenerowano fakturę!` })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiSendVoucherInvoiceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiSendVoucherInvoice)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.SEND_VOUCHER_INVOICE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(sendVoucherInvoiceAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(sendVoucherInvoiceAsync.success, action) || isActionOf(sendVoucherInvoiceAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(sendVoucherInvoiceAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: `Pomyślnie wysłano fakturę do klienta!` })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiGenerateVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiGenerateVoucher)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.GENERATE_VOUCHER };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(generateVoucherAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(generateVoucherAsync.success, action) || isActionOf(generateVoucherAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(generateVoucherAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: `Pomyślnie wygenerowano voucher!` })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiSendVoucherEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiSendVoucher)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.SEND_VOUCHER };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(sendVoucherAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(sendVoucherAsync.success, action) || isActionOf(sendVoucherAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(sendVoucherAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: `Pomyślnie wysłano voucher do klienta!` })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);